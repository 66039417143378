<template lang="pug">
li.mb-4
  .d-flex.justify-content-between.flex-column-on-sm.gap-2
    div.flex-grow-1
      a.btn.btn-link.text-left.ps-0(@click.prevent='toggleOpen' :href='job.ad_url')
        | {{job.title}}
      .d-flex.align-items-baseline.flex-column-on-sm.gap-2.flex-wrap.fs-6
        span.text-muted(v-if='job.location')
          | {{job.location }}
        job-badges(:job='job')
    span(v-if='job.visible === false')
      i
        | Stelle nicht mehr verfügbar
    button.btn.btn-primary.btn-sm(v-else @click.prevent='apply' :style="{ backgroundColor: organisation.company_color }")
      | {{ $t('js.wizard.job_list_item.jetzt_bewerben') }}
    bookmark-button(class="btn btn-secondary btn-sm" :job='job.bookmark_json || job')

  collapse-transition
    div(v-if='open')
      job-modal.mt-5( :job='job' :organisation='organisation' ref='modal')
      .d-flex.justify-content-end.mt-5
        button.btn.btn-outline-dark(@click.prevent='toggleOpen')
          | {{ $t('js.wizard.job_list_item.stelle_einklappen') }}

        
</template>

<script lang="ts" setup>
import { ref, nextTick } from "vue"
import JobBadges from "@/wizard/JobBadges.vue"
import JobModal from "@/wizard/JobModal.vue"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"
import BookmarkButton from "@/components/bookmark-button.vue"
import ahoy from "@/utils/ahoy"

defineProps<{
  job: frontend.Job
  organisation: frontend.Organisation
}>()
const open = ref(false)
const modal = ref<JobModal | null>(null)

const toggleOpen = () => {
  open.value = !open.value
  if (open.value) {
    ahoy.track('job_show', { id: props.job.id })
  }
}

const apply = () => {
  open.value = true
  nextTick(() => {
    setTimeout(() => {
      if (modal.value && modal.value.apply) {
        const el = modal.value.apply
        el.scrollIntoView({ behavior: "smooth" })
      }
    }, 300)
  })
}
</script>

<style scoped>
button {
  white-space: nowrap;
}
</style>
