export default {
  "de": {
    "js": {
      "components": {
        "apply": {
          "apply_form": {
            "bewerbung_unter_folgendem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbung unter folgendem Link vervollständigen"])},
            "bewerbung_vervollstandigen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbung vervollständigen"])},
            "closed_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider sind auf diese Stelle keine Bewerbungen mehr möglich."])},
            "closed_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbungen geschlossen"])},
            "die_firma_nutzt_ein_bewerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Firma nutzt ein Bewerbermangementsystem und bittet Sie Ihre Bewerbung unter folgendem Link zu vervollständigen. Sollten Sie in der Zwischenzeit bereits ein Eingangsbestätigungsschreiben (d.h. Sie haben 2 E-Mails zur Bewerbung erhalten - Eine von Empfehlungsbund und eine von der Organisation) von der Firma erhalten haben, so brauchen Sie dies nicht noch einmal zu tun."])},
            "gender_options": {
              "diverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers"])},
              "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
              "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])}
            },
            "link_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Anbieter"])},
            "link_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte folgen Sie diesem Link falls dieses Angebot für Sie interessant ist:"])},
            "living_in_germany_options": {
              "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich wohne in einem <b>anderen Land</b>."])},
              "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich lebe zur Zeit <b>in Deutschland</b> / mein offizieller Wohnsitz befindet sich in Deutschland."])}
            }
          },
          "apply_succeed": {
            "calendar": {
              "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender-Erinnerung herunterladen"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender-Erinnerung für 1 Woche später setzen, um ggf. die Bewerbung nachzufassen."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender-Erinnerung"])}
            },
            "company_finder": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " weitere passende Unternehmen mit ", _interpolate(_named("jobCount")), " ähnlichen Stellenangeboten."])}
            },
            "subscribe": {
              "button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("query")), " abonnieren"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Stellenangebote per E-Mail erhalten"])}
            },
            "subscription": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzen Sie unsere App, um sich über neue Stellen auf dem Laufenden zu halten."])}
            }
          },
          "empfehlungscode_list_item": {
            "entfernen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])}
          },
          "feedback_modal": {
            "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback einsenden"])},
            "yes_no": {
              "no_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
              "yes_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
            },
            "zufriedenheit_options": {
              "no_statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine Angabe"])},
              "rather_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eher zufrieden"])},
              "rather_unsatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eher unzufrieden"])},
              "satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zufrieden"])},
              "unsatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unzufrieden"])}
            }
          },
          "prefilled_note": {
            "adresse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
            "alle_eingaben_von_diesem_g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Eingaben von diesem Gerät löschen"])},
            "angaben_anpassen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben anpassen"])},
            "anhange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhänge"])},
            "anschreiben_kommentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anschreiben/Kommentar"])},
            "beim_absenden_einer_bewerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Absenden einer Bewerbung wurden alle Bewerbungsunterlagen automatisch in Ihrem Browser zwischengespeichert und bleiben dort für einige Zeit erhalten. Ihre Bewerbungsangaben werden nur durch Ihren Klick auf \"Jetzt Bewerben\" an die jeweilige Firma gesendet und nicht in unserer Datenbank gespeichert."])},
            "datenschutzerklarung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
            "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
            "hilfe_zum_datenschutz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe zum Datenschutz"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "schlie_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
            "schnellbewerbung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnellbewerbung"])},
            "sie_konnen_hier_mit_einem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können hier mit einem Klick eine Bewerbung mit Ihren vorherigen Angaben abschicken."])},
            "telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])}
          }
        },
        "apply_card": {
          "answer_those_questions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beantworten Sie bitte folgende Fragen der ", _interpolate(_named("company")), ":"])},
          "apply_now_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofort bewerben"])},
          "bms_privacy_hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Organisation ", _interpolate(_named("organisation")), " verwendet zur Verarbeitung Ihrer Bewerbung ein Bewerbermanagementsystem. Die Datenschutzerklärungen zur Speicherung und Verarbeitung Ihrer Daten <a href=\"", _interpolate(_named("privacyPolicyUrl")), "\" target=\"_blank\" style=\"text-decoration: underline\">finden Sie hier</a>."])},
          "error_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgende Angaben sind noch unvollständig:"])},
          "external_hint_hybrid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["WICHTIGER HINWEIS: Die ", _interpolate(_named("company")), " nutzt ein Bewerbermanagementsystem. Ihre Bewerbung wird vollständig an die Firma übermittelt. Sollten Sie allerdings innerhalb von 24h kein Eingangsbestätigungsschreiben erhalten, so nutzen Sie den Link zum Bewerbungsformular direkt im BMS des Kunden, den Sie nach Absenden erhalten und denen wir Ihnen dann auch noch einmal per E-Mail zusenden."])},
          "external_hint_legacy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["WICHTIGER HINWEIS: Die ", _interpolate(_named("company")), " nutzt ein Bewerbermanagementsystem. Sie werden mit Klick auf den Button „Bewerben“ erhalten Sie den Link und müssen sich dort nocheinmal registrieren."])},
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir freuen uns über ein kurzes Feedback zur Zufriedenheit mit Empfehlungsbund.de"])},
          "feedback_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback geben"])},
          "feedback_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihr Feedback! Dies hilft uns bei der Weiterentwicklung dieser Website sehr."])},
          "go_to_kanaleo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu kanaleo"])},
          "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bewerben Sie sich jetzt bei der ", _interpolate(_named("company")), " als ", _interpolate(_named("job")), "."])},
          "kanaleo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Arbeitgeber bittet Sie, in der Zwischenzeit auf kanaleo ein anonymes Feedback zu geben, wie Sie auf die Stelle aufmerksam geworden sind."])},
          "kanaleo_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihr Feedback an den Arbeitgeber!"])},
          "next_steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Schritte:"])},
          "postal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postanschrift"])},
          "previous_applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben sich bereits am ", _interpolate(_named("date")), " auf diese Stelle beworben."])},
          "privacy_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die <a href=\"https://www.empfehlungsbund.de/datenschutz#bewerbungsformular\" target='_blank'>Datenschutzbestimmungen des Empfehlungsbund und seiner Job-Communitys finden Sie hier</a>. Ihre Daten werden hier nur zwischengespeichert, und nach erfolgreicher Übermittlung an die Organisation gelöscht."])},
          "required_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vom Unternehmen geforderte Dokumente:"])},
          "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte prüfen Sie, ob Sie diese Grundanforderungen erfüllen:"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbung abschicken"])},
          "succeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbung wurde erfolgreich versendet!"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerben & Kontaktformular"])}
        },
        "berufe_selector": {
          "berufe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Berufe"])},
          "berufsprofile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufsprofile"])},
          "bewerbungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbungen"])},
          "empfehlende_arbeitgeber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["empfehlende Arbeitgeber"])},
          "empfehlungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungen"])},
          "impressions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressionen"])},
          "in_den_letzten_365_tagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den letzten 365 Tagen:"])},
          "jobsuchende": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobsuchende"])},
          "tage_bis_zur_ersten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage bis zur ersten Bewerbung"])},
          "wahlen_sie_einen_berufstyp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Berufstyp aus"])},
          "weitere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weitere"])}
        },
        "bookmark_button": {
          "aus_merkliste_entfernen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Merkliste entfernen"])},
          "beworben": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Am ", _interpolate(_named("date")), " beworben"])},
          "in_merkliste_aufnehmen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Merkliste aufnehmen"])},
          "nicht_mehr_verfuegbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Stelle ist nicht mehr verfügbar."])}
        },
        "cart": {
          "active_codes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Empfehlungscodes:"])},
          "cart_overview": {
            "alle_eingaben_von_diesem_g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Eingaben von diesem Gerät löschen"])}
          },
          "codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Empfehlungscodes"])},
          "codes_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie Empfehlungscodes?"])},
          "delete_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles löschen"])},
          "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespeicherte Jobs"])},
          "jobs_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Jobs gespeichert."])},
          "jobs_empty_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs finden"])},
          "to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkliste"])}
        },
        "cart_overview": {
          "explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Absenden einer Bewerbung wurden alle Bewerbungsunterlagen automatisch in Ihrem Browser zwischengespeichert und bleiben dort für einige\nZeit erhalten. Ihre Bewerbungsangaben werden nur durch Ihren Klick auf \"Jetzt Bewerben\" an die jeweilige Firma gesendet und nicht in unserer\nDatenbank gespeichert.\n"])},
          "info_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Bewerbungsdaten gespeichert."])},
          "personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre persönlichen Angaben"])},
          "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Bewerbung"])}
        },
        "codes_modal": {
          "allow_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Optional): Ich erlaube, dass Firmen bei denen ich mich über Empfehlungsbund bewerbe, den Aussteller des Codes (Die Firma, die mich empfohlen hat) kontaktieren dürfen."])},
          "save_code_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hinzufügen"])},
          "success_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben erfolgreich einen Empfehlungscode der ", _interpolate(_named("firma")), " hinzugefügt"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie einen Empfehlungscode erhalten?"])}
        },
        "contact_card": {
          "kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTAKT"])},
          "termin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin"])}
        },
        "eb_livestream": {
          "events": {
            "apply_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Bewerbung wurde versendet"])},
            "passus_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Kandidat wurde dem Netzwerk empfohlen"])},
            "passus_click_recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein A- oder B-Kandidat wurde dem Netzwerk empfohlen"])}
          }
        },
        "empfehlungscodes_field": {
          "active_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Empfehlungscodes:"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungscode hinzufügen"])},
          "did_you_receive_1_before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie einen"])},
          "did_you_receive_2_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungscode"])},
          "did_you_receive_3_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erhalten?"])},
          "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Empfehlungscode erhalten nur erstklassige Fachkräfte. Die Unternehmer der beteiligten Community-Portale verständigen sich regelmäßig,\nwer eine Empfehlung verdient hat. Vertrauen Sie dem Urteil der Experten. Überzeugen Sie sich vom strengen Regelwerk in den\n<a class=\"text-blue-500 hover:text-blue-700\" href=\"https://www.empfehlungsbund.de/faq#Bewerber\" target=\"_blank\">FAQs des Empfehlungsbunds</a>.\nNutzen Sie den Empfehlungscode für Ihren beruflichen Erfolg.\n"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungscode hinzufügen"])},
          "recommended_by": {
            "referenzcode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Empfohlener Kandidat, von ", _interpolate(_named("company"))])},
            "referenzcode2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Interessanter Kandidat, von ", _interpolate(_named("company"))])}
          },
          "use_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Referenz verwenden: Die Firma bei der mich hier bewerbe, darf gern die Organisation, die mir den Empfehlungscode ausgestellt hat, während meiner Bewerbung zu den Gründen der Empfehlung befragen."])}
        },
        "empfehlungsgraph": {
          "empfehlungsaktivitat_wird": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungsaktivität wird geladen"])}
        },
        "event_countdown": {
          "jetzt_anmelden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jetzt anmelden"])},
          "mehr_erfahren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehr erfahren"])},
          "minuten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuten"])},
          "noch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noch"])},
          "sekunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekunden"])},
          "stunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunden"])},
          "tage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])}
        },
        "filter_wizard": {
          "checkboxes": {
            "mehr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr anzeigen"])},
            "weniger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger anzeigen"])}
          }
        },
        "history_event_page": {
          "alle_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Services"])}
        },
        "lazyload": {
          "wird_geladen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wird geladen..."])}
        },
        "non_german_ip_interstitial": {
          "country_options": {
            "eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EU/EWR/Schengen"])},
            "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutschland"])},
            "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes Land"])}
          },
          "modal_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sieht so aus, als wärst du momentan nicht in Deutschland. Kannst du uns mitteilen, wo du dich aktuell aufhältst?"])},
          "notice": {
            "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Jobs4International.de wechseln"])},
            "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Angaben bezüglich Arbeitserlaubnis und Wohnsitz passen leider nicht zu den Anforderungen dieser Position. Wir empfehlen dir, auf Jobs4Internationals.de nach passenden Stellen zu suchen, bei denen diese Kriterien nicht zwingend sind. Dort werden Unternehmen gelistet, die Unterstützung bei der Beantragung einer Arbeitserlaubnis für Deutschland bieten und bei der Umsiedlung helfen.\n<br>\nNatürlich steht es dir frei, dich auch hier für diese Position zu bewerben. Allerdings ist die Chance, dass du keine Unterstützung erhältst und möglicherweise eine Absage bekommst, recht hoch.\n"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtige Information zu deiner Bewerbung"])}
          },
          "work_permit_options": {
            "germany_eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, ich besitze schon eine Arbeitserlaubnis für Deutschland oder ein anderes EU-Land."])},
            "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein, ich besitze bisher keine Arbeitserlaubnis für Deutschland oder ein anderes EU-Land."])}
          }
        },
        "partner_review": {
          "averageScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbewertung"])},
          "beratungScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beratung und Service"])},
          "bewertung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung"])},
          "bewertung_von": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung von"])},
          "commentRecommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterempfehlung"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
          "datum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
          "firmenname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
          "partnerstimmen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerstimmen"])},
          "partnerstimmen_wir_empfehl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerstimmen: Wir empfehlen Fachkräfte"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback aus der letzten Kundenbefragung - Was uns gut gefällt:"])},
          "sort_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortieren nach"])},
          "totalSatisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zufriedenheit"])},
          "year_membership": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Neues Mitglied"]), _normalize([_interpolate(_named("count")), " Jahr Mitgliedschaft"]), _normalize([_interpolate(_named("count")), " Jahre Mitgliedschaft"])])}
        },
        "prices": {
          "form": {
            "benotigen_sie_einen_strong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigen Sie einen <strong>Automatische Anbindung</strong> Ihres BMS an den Empfehlungsbund?"])},
            "bms_option": {
              "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich <strong>verwende</strong> ein eigenes <strong>Bewerbermanagementsystem</strong> (BMS)."])},
              "nein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe noch <strong>kein Bewerbermanagementsystem</strong>, bzw. bearbeite die Bewerbungen selbst."])}
            },
            "ich_wunsche_dass_die_stron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich wünsche, dass die <strong>Stellenanzeigen</strong> von meiner Karriereseite/aus meinem BMS übertragen bzw. gecrawlt werden."])},
            "ich_wunsche_die_automatisi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich wünsche die automatisierte <strong>Übermittlung der Bewerber</strong> aus der EB-Talent-Cloud in mein BMS. (Unter Machbarkeitsvorbehalt)"])},
            "konnen_sie_prinzipiell_ihr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Können Sie prinzipiell Ihre Absageschreiben anpassen, um den Hinweis auf die Empfehlungsbund-Community mit aufzunehmen"])},
            "mitarbeiter_kopfe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter (Köpfe)"])},
            "rejection_option": {
              "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, ich kann prinzipiell <strong>meine Absageschreiben anpassen</strong>, und den Verweis mit aufnehmen."])},
              "nein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein, ich kann prinzipiell meine <strong>Absageschreiben nicht anpassen</strong>."])}
            },
            "verwenden_sie_ein_eigenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie ein eigenes Bewerbermanagementsystem?"])}
          },
          "price_card": {
            "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchen"])},
            "star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise beziehen sich auf die Preislisten vom 01.07.2022. Jahrespreis abhängig von der Anzahl der Mitarbeitenden deutschlandweit."])},
            "starting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ab*"])}
          }
        },
        "prices_app": {
          "anbindung_am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbindung am"])},
          "angebot_erzeugen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot erzeugen"])},
          "anzahl_der_in_deutschland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der in Deutschland tätigen Mitarbeiter (Köpfe)"])},
          "anzahl_der_mitarbeiter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Mitarbeiter"])},
          "anzeige": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige"])},
          "anzeigenkampagne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigenkampagne"])},
          "anzeigenkampagne_fur_30_ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigenkampagne für 30 Tage auf Empfehlungsbund.de und allen passenden angeschlossenen Jobbörsen + Empfehlungsbund-Reichweitennetzwerk"])},
          "automatische_laufzeitverla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Laufzeitverlängerung von bis zu 60 Tagen bis eine Bewerbung über uns generiert wurde"])},
          "automatischer_import_amp_n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatischer Import &amp; nahtlose Integration Ihres Bewerbermanagementsystems an das Empfehlungsbund-Netzwerk"])},
          "automatischer_import_in_di": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatischer Import in die Bundesagentur für Arbeit"])},
          "des_empfehlungsbundes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["des Empfehlungsbundes"])},
          "die_empfehlungsbundmitglie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Empfehlungsbundmitgliedschaft ist eine Jahresflatrate für alle Stellenanzeigen Ihres Unternehmens und richtet sich nach der Anzahl der festangestellten Mitarbeiter in Deutschland."])},
          "die_mitgliedschaft_enthalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mitgliedschaft enthält alle Vorteile der Ad Hoc Kampagne gültig für 365 Tage"])},
          "ebms_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungsbund Bewerbermanagementsystem EBMS zur Optimierung der Candidate Journey ist ohne Zusatzkosten in der Basisversion für Empfehlungsbund-Mitglieder verfügbar"])},
          "fachkrafteempfehlungsnetzw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachkräfteempfehlungsnetzwerk des Empfehlungsbundes"])},
          "fachkrafteempfehlungsnetzw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachkräfteempfehlungsnetzwerk"])},
          "fachkrafteempfehlungsnetzw2_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie erhalten durch die Kooperation mit dem Empfehlungsbund-Netzwerk zusätzlich Empfohlene Kandidaten. Die Mitglieder des Empfehlungsbund empfehlen sich gegenseitig gute Bewerber die im Interview waren, jedoch nicht eingestellt wurden provisionslos weiter. Seit 2009 wurden so über 17k Fachkräfte für Informatik, kaufm. Berufsprofile und Informatik empfohlen."])},
          "fachkrafteempfehlungsnetzw_tootip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als ordentliches Mitglied haben Sie die Möglichkeit Kandidaten zu empfehlen und empfohlene Kandidaten zu erhalten. Hohe Sichtbarkeit innerhalb des dynamischen Talentpools an aktiv suchenden empfohlenen Kandidaten. Premiumsichtbarkeit im gesamten Empfehlungsbund-Netzwerk inkl. 14 weiterer Stellenplattformen."])},
          "festangestellten_mitarbeit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["festangestellten Mitarbeiter (Köpfe)"])},
          "firma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
          "individuelle_beratung_zur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuelle Beratung zur Stellenoptimierung inklusive"])},
          "jahresmitgliedschaft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresmitgliedschaft"])},
          "kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
          "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
          "netto_zahlbar_als_jahresmi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["netto, zahlbar als Jahresmitgliedschaft zu "])},
          "optionale_ma_nahmen_und_be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionale Maßnahmen und Beratung zur Steigerung der Bewerberzufriedenheit und Employer-Brand und Stellenoptimierung"])},
          "optionale_nutzung_aller_se": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionale Nutzung aller Services des Empfehlungsbund ohne Zusatzkosten:"])},
          "optionale_schaltung_in_mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionale Schaltung in Marken-Jobbörsen mit wechselnden Konditionen"])},
          "preis_berechnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis berechnen"])},
          "rechnungsadresse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsadresse"])},
          "registrieren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
          "schaltung_arbeitsagentur_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schaltung Arbeitsagentur, Bewerber-Rücklaufcontrolling, Gehaltsbenchmarks, Mitarbeiter-Werben-Mitarbeiter Programm, uvm."])},
          "termin_vereinbaren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin vereinbaren"])},
          "volle_teilnahme_am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volle Teilnahme am"])},
          "zugang_uber_das_erweiterte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang über das erweiterte Kooperationsnetzwerk des Empfehlungsbunds zu Hochschulen, Vereinen, User-Groups, Fachkräfteinitiativen"])}
        },
        "profile": {
          "conversation": {
            "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhang"])},
            "choose_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle eine Konversation aus, um die Nachrichten zu sehen."])},
            "confirm_modal": {
              "text_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du wirklich diese Konversation beenden? Dein Profil wird wieder für diese Firma anonymisiert und sie können dich nicht mehr kontaktieren. Bitte beachte, dass diese Aktion nicht rückgängig gemacht werden kann."])},
              "text_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du wirklich deine Daten für diese Firma verbergen? Nachdem du dies getan hast, kann diese Firma nur deine anonymen Daten sehen."])},
              "text_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du wirklich deine Daten für diese Firma freigeben? Diese Firma kann dann alle von dir angegebenen Daten sehen(Adresse, Telefonnummer, Lebenslauf, etc.)."])},
              "text_reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du wirklich diese Konversation wieder öffnen? Bitte beachte, dass diese Konversation nur wieder geöffnet wird, wenn beide Parteien zustimmen."])},
              "title_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation beenden"])},
              "title_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten verbergen"])},
              "title_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten offenlegen"])},
              "title_reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation wieder öffnen"])}
            },
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
            "end_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation beenden"])},
            "es_kann_eine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedoch kann es eine Weile dauern, bis dich ein Unternehmen entdeckt und kontaktiert. In der Zwischenzeit kannst du durch unser Job Board stöbern und dich schnell für die am besten personalisierte Position basierend auf deinen Präferenzen bewerben."])},
            "fett": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fett"])},
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Konversationen mit Unternehmen"])},
            "hint_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Denk daran, du bleibst weiterhin anonym für die kontaktierten Unternehmen. Wenn du dich jedoch dazu entscheidest, ein Jobangebot anzunehmen, kannst du deine persönlichen Daten (Name, Telefonnummer, Lebenslauf-Datei, usw.) vollständig an ein Unternehmen übertragen, indem du auf den Button 'Daten offenlegen' in ihrem Chat-Fenster unten klickst. Übrigens, du kannst die Konversation mit einem kontaktierten Unternehmen jederzeit beenden. In diesem Fall werden wir das Unternehmen NICHT benachrichtigen und deine Daten werden für sie verborgen bleiben."])},
            "hire_confirmations": {
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
              "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
              "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Unternehmen hat dich als bei ihnen angestellt markiert. Bitte bestätige dies, indem du auf die Schaltfläche 'Bestätigen' unten klickst. Falls dies ein Fehler ist oder du das Angebot ablehnen möchtest, klicke auf 'Ablehnen'"])}
            },
            "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ein Unternehmen an dir interessiert ist, eröffnen sie eine neue Unterhaltung und senden dir Benachrichtigungen über ihre Jobangebote. Wenn dies geschieht, wirst du auch per E-Mail darüber informiert"])},
            "kursiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursiv"])},
            "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten werden geladen..."])},
            "message_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups, deine Nachricht konnte nicht gesendet werden."])},
            "no_conversation_chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konversation ausgewählt"])},
            "no_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momentan hat noch kein Unternehmen mit dir Kontakt aufgenommen."])},
            "placeholders": {
              "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Konversation wurde beendet."])},
              "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht schreiben..."])}
            },
            "private_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten verbergen"])},
            "public_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten offenlegen"])},
            "reopen_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation wieder öffnen"])},
            "response_time": {
              "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort innerhalb von einem Tag"])},
              "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten innerhalb von ein paar Tagen"])},
              "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofortige Antwort"])},
              "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnelle Antwort"])}
            },
            "seen_by_org": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gesehen von ", _interpolate(_named("company"))])},
            "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
            "system_messages": {
              "backend": {
                "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Das Kandidatenprofil ist nicht mehr verfügbar. Diese Konversation wurde archiviert."])},
                "auto_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Diese Konversation wurde automatisch beendet. (30 Tage Inaktivität)"])},
                "closed_by_org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Sie haben die Konversation beendet."])},
                "closed_by_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Konversation wurde beendet."])},
                "confirm_hire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Talent hat das Jobangebot angenommen."])},
                "decline_hire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Talent hat das Jobangebot abgelehnt."])},
                "hire_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Sie haben das Talent als eingestellt markiert."])},
                "private_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Talent-daten wurden verborgen."])},
                "public_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Talent-daten wurden freigegeben."])},
                "reopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Diese Konversation wurde wieder geöffnet."])},
                "request_to_reopen_by_org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Sie haben darum gebeten, die Konversation wieder zu öffnen."])},
                "request_to_reopen_by_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Das Talent möchte die Konversation wieder öffnen."])}
              },
              "frontend": {
                "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Konversation wurde archiviert."])},
                "auto_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Konversation wurde automatisch beendet. (30 Tage Inaktivität)"])},
                "closed_by_org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Firma hat die Konversation beendet."])},
                "closed_by_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast diese Konversation beendet."])},
                "confirm_hire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du arbeitest jetzt für diese Firma."])},
                "decline_hire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast das Jobangebot abgelehnt."])},
                "hire_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Firma hat dich eingestellt. Um dies zu bestätigen oder abzulehnen, klicke auf das Button \"Details\" oben.\n"])},
                "private_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast deine Daten für diese Firma verborgen."])},
                "public_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast deine Daten für diese Firma freigegeben."])},
                "reopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Konversation wurde wieder geöffnet."])},
                "request_to_reopen_by_org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Firma möchte die Konversation wieder öffnen."])},
                "request_to_reopen_by_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast darum gebeten, die Konversation wieder zu öffnen."])}
              }
            },
            "upload_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups, da ist etwas schief gelaufen. Datei darf nicht größer als 10MB sein."])}
          },
          "hinweis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Diesen Angaben werden an Unternehmen erst dann freigeschaltet, wenn du dich für ein konkretes Angebot interessierst. Bis dahin können Unternehmen nur Angaben aus dem Tab \"Anonymes Profil\" durchsuchen. Du brauchst diese Seite auch erst dann ausfüllen, wenn ein Angebot für dich interessant ist."])},
          "info_banner": {
            "click_underneath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke unten auf deine Telefonnummer, um sie zu verifizieren."])},
            "dein_profil_ist_bereits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gut gemacht! Dein Profil ist bereits für Unternehmen sichtbar. Deine Traumstelle ist gleich um die Ecke."])},
            "hey_willkommen_zurueck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hey, Willkommen zurück!"])},
            "phone_verification_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sieht so aus, dass du deine Telefonnummer noch nicht verifiziert hast. Hinterlege deine Telefonnummer und erhalte nun auch Benachrichtigungen per SMS. So kannst du nichts mehr verpassen."])}
          },
          "job_offer": {
            "about_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über das Unternehmen"])},
            "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interessant"])},
            "accepted_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angenommene Job-Angebote"])},
            "answer_all_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte fülle alle Pflichtfelder aus sowie die Fragen, die dir gestellt wurden."])},
            "answer_those_questions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte beantworte folgende Fragen/ fülle alle Pflichtfelder aus, die von ", _interpolate(_named("company")), " erfordert wurden"])},
            "application_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Profil übermitteln"])},
            "can_close_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst diese Seite jetzt schließen."])},
            "check_the_following_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte überprüfe deine Angaben zur Übermittlung für die Stelle von ", _interpolate(_named("company")), ":"])},
            "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt am"])},
            "data_update_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Profildaten werden automatisch aktualisiert, wenn du hier Änderungen vornimmst."])},
            "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uninteressant"])},
            "hired_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingestellte Job-Angebote"])},
            "job_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zum Job-Angebot"])},
            "message_from_company": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Angebot von ", _interpolate(_named("company")), ":"])},
            "no_file_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte mindestens eine Datei auswählen und mitschicken."])},
            "no_job_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurzeit sind keine Job-Angebote verfügbar."])},
            "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Job-Angebot ist nicht mehr verfügbar."])},
            "past_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergangene Job-Angebote"])},
            "pending_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anstehende Job-Angebote"])},
            "postal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Postanschrift"])},
            "reject_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job-Angebot ablehnen"])},
            "reject_survey": {
              "es_ist_wichtig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist wichtig, dass du die beste Entscheidung für dich triffst, und wir respektieren voll und ganz deine Wahl. Falls du in Zukunft noch einmal unsere Unterstützung benötigst, zögere nicht, uns zu kontaktieren"])},
              "no_more_offer_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte keine weiteren Angebote von diesem Unternehmen erhalten."])},
              "questions": {
                "high_requirements": {
                  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anforderungen des Job-Angebots waren mir zu hoch:"])}
                },
                "low_salary": {
                  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Gehalt des Job-Angebots war mir zu niedrig:"])}
                },
                "options": {
                  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimme nicht zu"])},
                  "not_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht sicher"])},
                  "rather_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimme eher nicht zu"])},
                  "rather_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimme eher zu"])},
                  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimme zu"])}
                },
                "other": {
                  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe andere Gründe:"])}
                },
                "unfitting_description": {
                  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Job-Beschreibung des Job-Angebots war mir zu ungenau/langweilig:"])}
                },
                "unfitting_location": {
                  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Standort des Job-Angebots war mir nicht passend:"])}
                }
              },
              "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Job-Angebot ablehnen"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hast du ein paar Minuten Zeit? Antworten auf diese Fragen helfen uns, bessere Job-Angebote für dich zu finden."])},
              "vielen_dank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für deine Rückmeldung. Deine Entscheidung wurde erfolgreich registriert."])},
              "why": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Warum hast du das Job-Angebot von ", _interpolate(_named("company")), " abgelehnt?"])}
            },
            "send_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Profil übermitteln"])},
            "show_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details anzeigen"])},
            "state": {
              "text": {
                "accepted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Für dieses Job-Angebot hast du am ", _interpolate(_named("date")), " bereits dein Profil übermittelt."])},
                "documents_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Für dieses Job-Angebot hast du bereits am ", _interpolate(_named("date")), " dein Profil übermittelt."])},
                "hired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du wurdest für diese Stelle eingestellt."])},
                "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich finde dieses Job-Angebot"])},
                "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Job-Angebot ist nicht mehr verfügbar."])},
                "rescinded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Job-Angebot ist nicht mehr verfügbar."])}
              }
            },
            "stelle_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellenbeschreibung anzeigen"])}
          },
          "job_suggestions": {
            "all_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Jobvorschläge für diese Woche gesehen"])},
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für diese Woche sind keine neuen Stellen verfügbar. Schau nächste Woche wieder vorbei!"])},
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine wöchentlichen Jobvorschläge"])},
            "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Zwischenzeit in der du auf Angebote von Firmen wartest, haben wir dir hier ein paar passende Vorschläge vorbereitet. Du kannst dich mit deinem EB-Talents-Profil bei diesen Stellenangeboten auch selbst bequem bewerben.\n"])},
            "load_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Jobvorschläge laden"])},
            "page_von": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seite ", _interpolate(_named("page")), " von ", _interpolate(_named("total"))])}
          },
          "labels": {
            "active_recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Empfehlungen"])},
            "blockierte_unternehmen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockierte Unternehmen"])},
            "profile_link_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du deine Profile in sozialen Netzwerken verlinken?"])},
            "select_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle mindestens eine Lebenslauf-Datei aus."])},
            "select_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählten Dateien werden an Unternehmen übermittelt."])},
            "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte mindestens einen Lebenslauf hochladen."])}
          },
          "login": {
            "email_gesendet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail gesendet"])},
            "gib_deine_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib deine E-Mail-Adresse ein, um dich einzuloggen."])},
            "link_senden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link senden"])},
            "wir_haben_dir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben dir einen Einmal-Anmelde-Link per E-Mail geschickt. Bitte überprüfe sowohl deinen Posteingang als auch den Spam-Ordner, um die E-Mail zu finden."])},
            "wir_konnten_deine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir konnten deine E-Mail-Adresse nicht finden. Bitte überprüfe sie und versuche es erneut."])}
          },
          "phone_verification": {
            "bitte_gib_eine_gueltige": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige Telefonnummer ein."])},
            "code_falsch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Code ist nicht korrekt. Bitte versuche es erneut."])},
            "deine_telefonnummer_ist_noch_nicht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Telefonnummer ist noch nicht verifiziert"])},
            "es_kann_bis_zu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kann bis zu 5 Minuten dauern, bis die SMS ankommt."])},
            "gib_einfach_deine_telefonnummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib einfach deine Telefonnummer inklusive Ländervorwahl unten ein und klicke auf „SMS senden zur Verifizierung“. Du erhältst dann einen Code per SMS, den du hier eingeben kannst."])},
            "hint_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hint: Solange du dein Profil nicht freigeschaltet ist, sieht der Arbeitgeber die Telefonnummer auch nicht. Zusätzlich erhältst Du bei jeder Kontaktaufnahme durch ein Unternehmen eine SMS-Benachrichtigung. Dies lässt sich allerdings in den Profileinstellungen manuell deaktivieren.\n"])},
            "keine_sms_erhalten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine SMS erhalten? Klicke auf „Nochmal senden“."])},
            "leider_koennen_wir_deine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider können wir deine Telefonnummer nicht verifizieren. Bitte überprüfe deine Eingabe und versuche es erneut."])},
            "nochmal_senden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nochmal senden"])},
            "sms_senden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS senden zur Verifizierung"])},
            "telefonnummer_bereits_verifiziert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Telefonnummer wurde bereits verifiziert."])},
            "telefonnummer_verifiziert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Telefonnummer ist verifiziert."])},
            "telefonnummer_verifiziert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst dich jetzt entspannt zurücklehnen und auf die ersten Anfragen warten."])},
            "ueberpruefe_dein_hand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfe dein Handy auf den SMS-Code"])},
            "verifizierte_profile_wirken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierte Profile erhalten mehr Aufmerksamkeit und schnellere Kontaktaufnahme per SMS, wenn neue Anfragen von Arbeitgebern eingehen."])},
            "zu_viele_anfragen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu vielen Anfragen. Bitte versuche es noch mal in 1 Minute."])}
          },
          "profile_preview": {
            "dein_anonymes_kurzprofil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein anonymes Kurzprofil wird übertragen"])},
            "deine_daten_wurden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Daten wurden erfolgreich aktualisiert."])},
            "nur_folgende_daten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUR folgende Daten werden veröffentlicht und sind für entsprechende Unternehmen sichtbar:"])}
          },
          "profile_settings": {
            "bist_du_sicher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du sicher, dass du dein Profil löschen möchtest?"])},
            "delete_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil löschen"])},
            "delete_profile_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du dein Profil löschst, werden alle deine Daten unwiderruflich gelöscht und du zukünftig keine Job-Angebote mehr erhalten. Falls du dich später wieder anmelden möchtest, musst du ein neues Profil erstellen."])},
            "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, Profil löschen"])},
            "nein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein, Profil behalten"])},
            "sms_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS-Benachrichtigung"])},
            "sms_notification_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du die SMS-Benachrichtigung aktivierst, werden wir dich über neue Nachrichten von Unternehmen informieren. Bitte beachte, dass nur verifizierte Telefonnummern SMS-Benachrichtigungen erhalten können."])},
            "sms_notification_ist_aktiviert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS-Benachrichtigung ist derzeit aktiviert"])},
            "sms_notification_ist_deaktiviert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS-Benachrichtigung ist derzeit deaktiviert"])},
            "sms_notification_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS-Benachrichtigung deaktivieren"])},
            "sms_notification_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS-Benachrichtigung aktivieren"])}
          },
          "quick_info": {
            "abmelden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
            "bist_du_sicher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du sicher, dass du dich abmelden möchtest?"])},
            "bist_du_sicher_dein_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du sicher? Dein Profil wird nicht mehr sichbar für Unternehmen sein."])},
            "privat_schalten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat schalten"])},
            "profil_veroeffentlichen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil veröffentlichen"])},
            "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
            "telephone_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer hinzufügen"])},
            "verify_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizieren"])}
          },
          "recommendations": {
            "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirklich löschen?"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hast du einen Empfehlungscode? Gib ihn hier ein!"])}
          },
          "tables": {
            "abschluss_und_zertifikate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss und Zertifikate"])},
            "allgemeine_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil-Informationen"])},
            "anstellungsform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anstellungsform"])},
            "bearbeiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellenbezeichnungen bearbeiten"])},
            "berufliche_sprachkenntnisse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufliche Sprachkenntnisse"])},
            "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staatsangehörigkeit"])},
            "deine_allgemeinen_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine allgemeinen Informationen"])},
            "deine_ausbildung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Ausbildung"])},
            "deine_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine angegebenen Empfehlungscodes"])},
            "deine_daten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine persönlichen Daten"])},
            "deine_faehigkeiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Fähigkeiten"])},
            "deine_wunschstelle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wunschstelle"])},
            "deine_zertifikate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Zertifikate"])},
            "du_hast_noch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast noch keine Zertifikate hinzugefügt."])},
            "empfehlungscode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungscode"])},
            "erfahrungsstufe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahrungsstufe"])},
            "firmenblockliste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenblockliste"])},
            "firmenname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
            "gewuenschter_arbeitsort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewünschter Arbeitsort"])},
            "hoechster_abschluss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höchster Abschluss"])},
            "jobs_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewünschte Stellenbezeichnungen"])},
            "kein_unternehmen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Unternehmen blockiert"])},
            "keine_empfehlungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine aktiven Empfehlungen"])},
            "kompetenz_stufe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompetenzstufe"])},
            "kompetenzen": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kompetenz"]), _normalize(["Kompetenzen"]), _normalize(["Kompetenzen"])])},
            "kompetenzen_bearbeiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompetenzen bearbeiten"])},
            "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
            "name_des_zertifikats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Zertifikats"])},
            "neues_zertifikat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Zertifikat"])},
            "niveau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau / Stufe (optional)"])},
            "niveau_stufe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau / Stufe"])},
            "phone_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer verifizieren"])},
            "profile_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil-Vorschau und freischalten"])},
            "profile_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil-Einstellungen"])},
            "sprache": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
            "sprachkenntnisse_bearbeiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachkenntnisse bearbeiten"])},
            "stellenbezeichnung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellenbezeichnung"])},
            "zertifikat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikat"])},
            "zertifikat_bearbeiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikat bearbeiten"])},
            "zertifikat_hinzufuegen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikat hinzufügen"])}
          },
          "tabs": {
            "anonymous_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymes Profil"])},
            "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen"])},
            "job_board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellenbörse"])},
            "job_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job-Angebote"])},
            "job_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job-Abonnements"])},
            "personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten zur Person"])}
          }
        },
        "progress": {
          "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikate"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
          "competence_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompetenzstufen"])},
          "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erledigt"])},
          "country_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heimatland"])},
          "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss"])},
          "degree_specialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschlussrichtung"])},
          "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente/CV"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
          "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle aus, welche Dokumente du übertragen willst"])},
          "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
          "germany_residency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufenthaltsgenehmigung"])},
          "hint": {
            "application_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name, Adresse und Telefonnummer."])},
            "company_blocklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du Firmen blockieren, die du nicht kontaktieren möchtest"])},
            "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschlüsse und Zertifikate"])},
            "job_desire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellenbezeichnung, Arbeitsort, Gehaltswunsch, ..."])},
            "recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du deinen Empfehlungscode eingeben"])},
            "skillset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitserlaubnis, Kompetenzen, Sprachen, ..."])}
          },
          "job_titles_preference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellenbezeichnungen"])},
          "language_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachkenntnisse"])},
          "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
          "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsorte"])},
          "missing_steps": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nächster Schritt: Leg deine <b> ", _interpolate(_named("fields")), "</b> an"])},
          "portal_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karrierebereich"])},
          "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
          "ready_to_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umzugsbereitschaft"])},
          "salary_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehaltsintervall"])},
          "salary_preference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehaltsvorstellung"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
          "sub_job_types_preference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anstellungsformen"])},
          "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
          "work_permit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitserlaubnis"])}
        },
        "scroll_to_top": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganz nach oben scrollen."])}
        },
        "talent_profile": {
          "answers": {
            "eu_citizen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin EU-Bürger"])},
            "limited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befristete Arbeitserlaubnis"])},
            "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
            "no_work_permit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Arbeitserlaubnis"])},
            "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbefristete Arbeitserlaubnis"])},
            "work_permit_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine der genannten Optionen"])},
            "work_permit_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich verfüge über eine gültige Arbeitserlaubnis in Deutschland"])},
            "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
          },
          "competences": {
            "chosen_competences": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Bitte wähle min. eine Kompetenz aus oder nutze die Eingabe für eigene Berufsabschlüsse oder Fähigkeiten."]), _normalize(["Du hast 1 Kompetenz ausgewählt."]), _normalize(["Du hast ", _interpolate(_named("count")), " Kompetenzen ausgewählt."])])},
            "competence_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Kompetenz suchen oder hinzufügen"])},
            "custom_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine eingegebenen Kompetenzen"])},
            "frequently_chosen_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Häufig gewählte Kompetenzen"])},
            "hide_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompetenzen ausblenden"])},
            "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Um die bestmöglichen Jobtitelvorschläge zu erhalten, empfehlen wir dir, mindestens eine der angegebenen Kompetenzen auszuwählen. Diese Angabe ermöglicht es uns, dir passgenaue und relevante Ergebnisse zu präsentieren. Deine Auswahl der Kompetenzen trägt maßgeblich dazu bei, dass wir dir die besten Karrieremöglichkeiten präsentieren können. Vielen Dank für deine Zusammenarbeit!"])},
            "is_important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist Wichtig"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Wähle dein Kompetenzfeld"])},
            "label2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Füge mindestens eine Kompetenzen hinzu"])},
            "more_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr Kompetenzen anzeigen"])},
            "no_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für deinen eingegebenen Suchbegriff konnten keine Kompetenzen gefunden werden. Klicke auf 'Hinzufügen', um deine eigenen Kompetenzen einzufügen"])},
            "no_portaltype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte zuerst ein Kompetenzfeld auswählen"])},
            "only_5_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst maximal 5 Kompetenzen auswählen."])},
            "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Kompetenzen"])},
            "significance_levels": {
              "important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtig"])},
              "not_important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger wichtig"])}
            },
            "weiter_entwickeln": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen für Angebote"])}
          },
          "degrees": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist dein höchster/bzw. letzter Berufsabschluss oder Schulabschluss?"])},
            "options": {
              "apprenticeship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufsausbildung"])},
              "bachelor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor"])},
              "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master/Diplom/Staatsexamen"])},
              "meister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterabschluss"])},
              "no_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Abschluss"])},
              "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
              "phd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion/Doktor/PhD"])},
              "technician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Techniker"])}
            }
          },
          "degree_specialization": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachrichtung des Studiums/Name des Berufsabschlusses"])}
          },
          "errors": {
            "combined_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib mindestens eine Kompetenz, deren Kompetenzstufe und einen Bereich an, in denen du dich weiterentwickeln möchtest."])},
            "competence_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib alle Kompetenzstufen an."])},
            "competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib mindestens eine Kompetenz und maximal 5 Kompetenzen an."])},
            "data_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du musst die Datenschutzerklärung akzeptieren."])},
            "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle einen Abschluss aus."])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib eine gültige E-Mail-Adresse ein."])},
            "important_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib mindestens einen Bereich an, in denen du dich weiterentwickeln möchtest."])},
            "job_titles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib mindestens eine Stellenbezeichnung an."])},
            "language_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib mindestens eine Sprache an."])},
            "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups. Leider gab es ein Problem bei der Erstellung deines Profils. Bitte versuche es später noch einmal."])},
            "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du musst die Datenschutzerklärung akzeptieren."])},
            "sub_job_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle mindestens eine Anstellungsform aus."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups. Leider gab es ein Problem bei der Aktualisierung deines Profils. Bitte versuche es später noch einmal."])},
            "upload_and_select_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte mindestens eine Datei hochladen und auswählen."])},
            "work_locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle mindestens eine(n) Option(Arbeitsort) aus."])},
            "work_permit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du musst alle Fragen beantworten."])}
          },
          "experience_levels": {
            "explanations": {
              "advanced_beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - 2 Jahre Erfahrung"])},
              "competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 - 5 Jahre Erfahrung"])},
              "expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr als 10 Jahre Erfahrung"])},
              "novice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Erfahrung"])},
              "proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 - 10 Jahre Erfahrung"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schätze deine Kompetenzstufe ein:"])},
            "options": {
              "advanced_beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junior"])},
              "competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortgeschritten"])},
              "expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experte"])},
              "novice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfänger"])},
              "proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior"])}
            }
          },
          "generic": {
            "abbrechen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
            "bearbeiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
            "beenden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beenden"])},
            "in_bearbeitung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung"])},
            "schliessen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
            "speichern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
            "status": {
              "application_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups. Deine Bewerbung konnte nicht abgeschickt werden. Bitte versuche es später noch einmal."])},
              "application_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Bewerbung wurde erfolgreich abgeschickt."])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups. Leider gab es ein Problem bei der Aktualisierung deiner Daten. Bitte versuche es später noch einmal."])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Daten wurden erfolgreich aktualisiert."])},
              "synchronised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Daten wurden automatisch mit deinem Profil synchronisiert."])}
            },
            "validations": {
              "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld ist ein Pflichtfeld."])}
            }
          },
          "important_competences": {
            "explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle alle Kompetenzen aus, für die du Angebote erhalten möchtest, bzw. in denen du dich weiterentwicklen möchtest"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für diese Themen hätte ich gerne Angebote?"])},
            "not_chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle mindestens 1 Kompetenz aus"])}
          },
          "job_subscriptions": {
            "active_jobs_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Job-Abonnements"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbestellen"])},
            "do_you_know_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennst du schon unsere App für iOS und Android? Bleib auch unterwegs auf dem Laufenden und stöbere durch alle Stellenanzeigen des Empfehlungsbundes. <div><a href='https://www.empfehlungsbund.de/app'>Mehr Infos findest du hier, mit allen Downloadmöglichkeiten</a></div>"])},
            "job_search_and_abo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job-Suche und Abonnements"])},
            "no_active_jobs_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider hast du noch keine aktiven Job-Abonnements."])},
            "placeholder_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo?"])},
            "placeholder_q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was?"])},
            "search_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
            "search_position_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort, z.B. \"Dresden\""])},
            "search_q_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobtitel oder Suchwort, z.B. \"Entwickler\" oder \"Java\""])},
            "stellen_durchsuchen_und_we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen durchsuchen und weitere Job-Abos hinzufügen"])},
            "subscription_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups. Leider gab es ein Problem bei der Erstellung deines Job-Abonnements. Bitte versuche es später noch einmal."])},
            "subscription_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Job-Abonnement wurde erfolgreich angelegt."])},
            "suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfohlene Abonnements für dich:"])}
          },
          "job_titles": {
            "examples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bsp: Softwareentwickler, Vertriebsleiter, Marketingmanager"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teile uns deine gewünschte Stellenbezeichnung mit"])},
            "label_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine gewünschten Stellenbezeichnungen"])},
            "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für diese Suche gibt es leider keine Ergebnisse. Klicke auf Hinzufügen oder drücke Enter, um deine Eingabe zu übernehmen."])},
            "often_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Häufig verwendete Stellenbezeichnungen"])},
            "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Stellenbezeichnungen..."])},
            "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine angegebene Stellenbezeichnungen:"])},
            "suggestion_hint": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hinweis: Nutze das Suchfeld, um intelligente Vorschläge zu erhalten."]), _normalize(["Hier ein Vorschlag für einen Stellentitel."]), _normalize(["Hier ein paar Vorschläge für einen Stellentitel."])])}
          },
          "languages": {
            "fluency_levels": {
              "a1_a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundkenntnisse (A1/A2)"])},
              "b1_b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gut bis Fließend (B1/B2)"])},
              "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhandlungssicher (C1, DSH, TestDaF, IELTS6+, TOEFL 100+)"])},
              "c2_native": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muttersprachliches Niveau (C2)"])},
              "text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Wie gut beherrschst du diese Sprache?"]), _normalize(["Wie gut beherrschst du diese Sprachen?"])])}
            },
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
            "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib eine Sprache ein, z.B. Englisch, Spanisch, ..."])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Sprachen sprichst du?"])}
          },
          "motivation": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist dir bei einem Arbeitgeber/Stelle besonders wichtig?"])},
            "label_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Wünsche an einen Arbeitgeber"])}
          },
          "pages": {
            "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
            "add_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Github, Stackoverflow, LinkedIn, Xing, eigene Website, ..."])},
            "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
            "fields": {
              "company_blocklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockierte Arbeitgeber"])},
              "competence_ids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Kompetenzen"])},
              "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
              "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein gewünschter Arbeitsort"])},
              "recommendation_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungscode"])}
            },
            "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
            "prefill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser System hat erkannt, dass du zuvor einen Empfehlungscode und/oder andere Informationen basierend auf Ihrer vergangenen Sitzung eingegeben hast. Möchtest du diese Felder vorab ausfüllen?"])},
            "prefill_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausfüllen"])}
          },
          "phone_number": {
            "placeholders": {
              "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B. +49"])},
              "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B. 123456789"])}
            }
          },
          "placeholders": {
            "company_blocklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname, z.B. Siemens, Bosch, ..."])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine E-Mail-Adresse"])},
            "motivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bsp: Arbeitsatmosphäre, Weiterbildungsmöglichkeiten, ..."])},
            "degree_specialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Optional) Bsp: Informatik, Maschinenbau, ..."])},
            "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag in Euro, z.B. 50.000€"])}
          },
          "profile": {
            "personal_data": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Daten"])}
            }
          },
          "questions": {
            "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du EU-Bürger oder hast du eine gültige Arbeitserlaubnis in Deutschland?"])},
            "current_employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du derzeit berufstätig?"])},
            "employer_hints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum benötigen wir diese Informationen? Unserer Erfahrung nach können Jobangebote von früheren Arbeitgebern unattraktiv sein. Deshalb bitten wir dich, deine aktuellen/früheren Arbeitgeber hier anzugeben, um den bestmöglichen Job für dich zu finden. Wenn du weiterhin potenzielle Jobangebote von bisherigen Firmen erhalten möchtest, lasse das Feld oben bitte leer. Deine Privatsphäre ist uns wichtig, und wir teilen deine Informationen nicht mit anderen Personen oder Gruppen."])},
            "employer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diskretion ist uns wichtig! Gib hier alle Arbeitgeber an, die dich nicht finden sollen."])},
            "german_residency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnst du jetzt in Deutschland?"])},
            "german_residency_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnort in Deutschland"])},
            "last_employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warst du in den letzten 12 Monaten berufstätig?"])},
            "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Staatsangehörigkeit"])},
            "ready_to_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du bereit, innerhalb Deutschlands zu arbeiten?"])},
            "ready_to_move_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereitschaft in Deutschland"])},
            "work_permit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Arbeitserlaubnis"])}
          },
          "registration_hints": {
            "data_privacy_accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
            "ich_habe_die": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich stimme zu, dass ich im Talentpool anonymisiert von Unternehmen gefunden werden kann. Ich akzeptiere die elektronische Speicherung meiner Daten gemäß der <a href=\"/datenschutz\">Datenschutzerklärung</a>."])},
            "nach_erfolgreich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach erfolgreicher Registrierung erhältst du eine E-Mail mit einem Aktivierungscode. Bitte beachte, dass dieser Code innerhalb von 3 Tagen nach Erhalt verfällt. <br><br>Sobald du deinen Account aktiviert hast, steht dir ein persönliches Profil zur Verfügung, das du nach deinen Wünschen konfigurieren kannst. Bitte beachte, dass du danach dein Profil noch für Arbeitgeber auffindbar schalten musst."])},
            "unser_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Talents Program bietet dir eine umfassende Plattform zur Karriereentwicklung und -vermittlung. Nutze diese Gelegenheit, um dein Profil zu optimieren und Unternehmen auf sich aufmerksam zu machen. Wir sind davon überzeugt, dass wir dir dabei helfen können, deine beruflichen Ziele zu erreichen."])},
            "wir_freuen_uns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir freuen uns, dich in unserem Netzwerk willkommen zu heißen und stehen Ihnen bei Fragen gerne zur Verfügung. "])}
          },
          "response_modal": {
            "error_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider gab es ein Problem bei der Erstellung deines Profils. Versuche es später noch einmal."])},
            "error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler! Bitte versuche es später noch einmal."])},
            "success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben dir gerade eine Mail geschickt. Bitte überprüfe nun dein E-Mail-Postfach und ggf. auch den Spam/Junk-Ordner und klicke in der Mail auf den Aktivierungslink. Nach der Aktivierung kannst du auf dein Profil zugreifen und von unserem Empfehlungsbund-Talent profitieren, um deinen Traumarbeitgeber/-firma zu finden. Wir wünschen dir einen guten Start und eine erfolgreiche Karriere."])},
            "success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast fertig!<br>Bitte schaue jetzt in dein Postfach"])}
          },
          "salary_intervals": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewünschtes Gehalt (Brutto, EUR)"])},
            "options": {
              "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stundenlohn"])},
              "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatsgehalt"])},
              "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresgehalt"])}
            }
          },
          "stepper_steps": {
            "fifth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine E-Mail-Adresse"])},
            "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Profil"])},
            "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Präferenzen"])},
            "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
            "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Stellenbezeichnungen"])},
            "show_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler anzeigen"])},
            "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über dich"])}
          },
          "sub_job_types": {
            "group_1": {
              "fachkraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachkraft"])},
              "freelancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freiberuflich"])},
              "fuehrungskraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führungskraft"])},
              "hilfskraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfskraft"])},
              "lernende": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lernende"])}
            },
            "group_2": {
              "ausbildung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufliche Ausbildung"])},
              "duales_studium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duales Studium"])},
              "praktikum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktikum oder Pflichtpraktikum"])},
              "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freiwilligenstelle"])},
              "werkstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkstudent"])}
            },
            "main_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anstellungsform:"])},
            "options": {
              "ausbildung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausbildung"])},
              "duales_studium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duales Studium"])},
              "fachkraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachkraft"])},
              "freelancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freiberuflich"])},
              "fuehrungskraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führungskraft"])},
              "hilfskraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfskraft"])},
              "praktikum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktikum"])},
              "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freiwilligenstelle"])},
              "werkstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkstudent"])}
            },
            "sub_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Rolle suchst du ?"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Anstellungsform suchst du?"])}
          },
          "success": {
            "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast geschafft! Bitte überprüfe deine E-Mail-Adresse für den Aktivierungslink. Nach der Aktivierung kannst du auf dein Profil zugreifen und von unserem Empfehlungsbund-Talent profitieren, um Ihren Traumarbeitgeber/-firma zu finden. Wir wünschen Ihnen einen guten Start und eine erfolgreiche Karriere."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Profil wurde erfolgreich aktualisiert."])}
          },
          "work_location": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein gewünschter Arbeitsort:"])},
            "options": {
              "any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutschlandweit"])},
              "only_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Full-Remote/100% Homeoffice"])},
              "onsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor-Ort in folgenden Standorten"])},
              "onsite_with_radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor-Ort in einem Standort mit Umkreis"])},
              "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Homeoffice"])},
              "willing_to_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin umzugsbereit"])}
            },
            "options_explain": {
              "any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bist umzugsbereit und suchst deutschlandweit nach Jobs."])},
              "onsite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du suchst nach Jobs in: ", _interpolate(_named("ort")), "."])},
              "onsite_with_radius": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du suchst nach Jobs in einem Umkreis von ", _interpolate(_named("radius")), " km um: ", _interpolate(_named("ort"))])},
              "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du suchst nach Jobs, die zu 100% im Homeoffice sind."])}
            },
            "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Umkreis von"])},
            "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bsp: Dresden, Berlin, München, ..."])}
          }
        }
      },
      "elements": {
        "upload_field": {
          "is_uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird hochgeladen:"])},
          "or_drag_and_drop_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder hier herziehen und ablegen"])},
          "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
          "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählt"])},
          "upload_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Dokument hochladen"])}
        }
      },
      "form": {
        "messages": {
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist keine gültige E-Mail-Adresse"])},
          "fileRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen mindestens eine Datei hochladen"])},
          "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("property")), " muss angegeben werden"])}
        },
        "properties": {
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
          "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar/Anschreiben"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie Ihr Wohnland aus der Liste aus"])},
          "current_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In welchem Land lebst du aktuell?"])},
          "current_work_permit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hast du bereits eine Arbeitserlaubnis für Deutschland bzw. EU?"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
          "feedback": {
            "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmerkungen"])}
          },
          "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbungsunterlagen"])},
          "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
          "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewünschte Anrede"])},
          "github_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Github URL"])},
          "home_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heimatland angeben"])},
          "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
          "livingInGermany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo befindet sich Ihr aktueller Wohnort?"])},
          "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsort"])},
          "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
          "question_answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbungskurzfrage"])},
          "recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Würden Sie Empfehlungsbund weiterempfehlen?"])},
          "satisfaction_with_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie zufrieden waren Sie bislang mit dem Bewerbungsvorgang?"])},
          "satisfaction_with_bms_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie zufrieden waren Sie mit dem Bewerbermanagementsystem(en)?"])},
          "satisfaction_with_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie zufrieden sind Sie mit dem Stellenangebot des Portals?"])},
          "satisfaction_with_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie zufrieden waren Sie mit der Präsentation der Unternehmen?"])},
          "satisfaction_with_usability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie zufrieden sind Sie mit der Bedienung des Portals?"])},
          "search_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchbegriff(e)"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße & Hausnummer"])},
          "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
          "telephone_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonvorwahl"])},
          "telephone_suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
          "website_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hast du eine eigene Website?"])},
          "would_use_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie sich noch einmal bewerben, würden Sie sich wieder für diese Community entscheiden?"])},
          "xing_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xing URL"])}
        }
      },
      "wizard": {
        "app": {
          "all_stellenangebot_der": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Stellenangebote der Empfehlungsbund-Partner in einer App."])},
          "bewerben": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerben"])},
          "erhalten_sie_neue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie neue Stellenanzeigen als E-Mail oder als Push-Nachricht auf Ihrem Gerät."])},
          "kein_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Login, keine Registrierung"])},
          "sie_haben_eine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben eine interessante Stelle gefunden, dann bewerben Sie sich direkt über die App!"])},
          "sie_koennen_mit_der": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können mit der Jobsuche sofort beginnen, ohne Ihre persönlichen Daten zu hinterlegen."])},
          "such_abo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Such-Abo"])}
        },
        "components": {
          "fairekarriere_widget": {
            "bewertungen_von_mitarbeite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen von Mitarbeitern und Bewerbern:"])}
          },
          "organisation_details": {
            "auszeichnungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszeichnungen"])},
            "behance_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behance Profil"])},
            "facebook_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook Profil"])},
            "flickr_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flickr Profil"])},
            "gesuchte_kompetenzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesuchte Kompetenzen:"])},
            "github_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub Profil"])},
            "instagram_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram Profil"])},
            "karriere_seite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karriere-Seite"])},
            "linkedin_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn Profil"])},
            "pinterest_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinterest Profil"])},
            "podcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmens-Podcast"])},
            "standorte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
            "twitter_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter Profil"])},
            "weiterfuhrende_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterführende Links"])},
            "youtube_kanal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube Kanal"])}
          },
          "reward_card": {
            "logo_der_auszeichnung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo der Auszeichnung"])},
            "mehr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])}
          },
          "subscribe_card": {
            "dann_melden_sie_sich_hier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dann melden Sie sich hier mit Ihrer E-Mail an. Sie werden dann sofort informiert, sobald passende Arbeitgeber bzw. Stellenangebote für Ihre Suchanfragen gefunden werden."])},
            "ihr_wunschstelle_ist_nicht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Wunschstelle ist nicht dabei?"])},
            "legen_sie_sich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oder legen Sie sich ein anonymes Such-Profil in 5 Minuten bei Empfehlungsbund-Talents an, und erhalten Sie Anfragen von interessierten Firmen."])},
            "profil_anlegen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Profil-Anlegen"])},
            "sentence": {
              "with_location_query": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenn Sie sich nur für Stellen aus <strong>", _interpolate(_named("location")), " für ", _interpolate(_named("query")), "</strong> interessieren, dann finden Sie auch alle passenden Stellen auf ", _interpolate(_named("name"))])},
              "with_query": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenn Sie sich nur für Stellen <strong>für ", _interpolate(_named("query")), "</strong> interessieren, dann finden Sie auch alle passenden Stellen auf ", _interpolate(_named("name"))])}
            }
          },
          "subscribe_form": {
            "ihre_mail_de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ihre", "@", "mail.de"])},
            "wir_haben_ihnen_soeben_ein": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wir haben Ihnen soeben eine Bestätigungsmail an ", _interpolate(_named("email")), " geschickt. Bitte klicken Sie auf den Link in der Mail, um über neue interessante Stellen informiert zu werden"])}
          }
        },
        "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
        "filter_bar": {
          "100_remote_ausblenden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100%-Remote ausblenden"])},
          "arbeitgeber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitgeber"])},
          "arbeitsort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsort"])},
          "laden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden"])},
          "less_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter ausblenden"])},
          "mehr_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter einblenden"])},
          "nur_100_remote_stellen_anz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur 100%-Remote Stellen anzeigen"])},
          "radius_zur_umkreissuche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radius zur Umkreissuche"])},
          "standort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
          "suchbegriff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchbegriff"])},
          "tatigkeitsfeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeitsfeld"])},
          "unternehmensgro_e_mitarbei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensgröße (Mitarbeiter)"])}
        },
        "job_list": {
          "initiativ_bewerben": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiativ bewerben"])},
          "mehr_jobs_length_show_anze": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mehr (", _interpolate(_named("jobs_length_show")), ") anzeigen"])},
          "passende_stellenangebote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passende Stellenangebote:"])}
        },
        "job_list_item": {
          "jetzt_bewerben": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt bewerben!"])},
          "stelle_einklappen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stelle einklappen"])}
        },
        "job_modal": {
          "arbeitsort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsort:"])},
          "schlagworte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagworte:"])}
        },
        "location": {
          "100_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Remote"])},
          "anderer_arbeitsort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderer Arbeitsort"])},
          "firmen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 Firma"]), _normalize([_interpolate(_named("count")), " Firmen"])])},
          "haufige_arbeitsorte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Häufige Arbeitsorte:"])},
          "home_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home-Office"])},
          "jobs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 Stelle"]), _normalize([_interpolate(_named("count")), " Stellen"])])},
          "standort_durch_dein_gerat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort durch dein Gerät bestimmen"])},
          "stellen_in_city_suchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
          "ungefahrer_standort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungefährer Standort"])},
          "wird_bestimmt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...wird bestimmt"])}
        },
        "organisation_card": {
          "anzahl_mitarbeiter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Mitarbeiter"])},
          "bewertung": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mitarbeiterbewertung: ", _interpolate(_named("rating")), "/5"])},
          "bewertung_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter:"])},
          "mehr_zur_organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr zur Organisation"])},
          "very_good_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehr gute <br> Bewerbererfahrung!"])},
          "weniger_zur_organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger zur Organisation"])}
        },
        "organisation_list": {
          "found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 Firma gefunden."]), _normalize([_interpolate(_named("count")), " Firmen gefunden."])])},
          "loaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird geladen..."])}
        },
        "remote_type": {
          "full_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100%-Remote"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angabe"])},
          "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid Home-Office"])}
        },
        "salary_interval": {
          "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Std."])},
          "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Monat"])},
          "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Jahr"])}
        },
        "sub_job_type": {
          "types": {
            "all": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])}
            },
            "ausbildung": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausbildung"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebliche Ausbildung"])}
            },
            "duales_studium": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duales Studium"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praxispartner, ausbildungs oder praxisorientiert"])}
            },
            "fachkraft": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachkraft"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollzeit/Teilzeit"])}
            },
            "freelancer": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freiberuflich"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancer"])}
            },
            "fuehrungskraft": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führungskraft"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager, Leiter, Directors"])}
            },
            "hilfskraft": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfskraft"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aushilfskräfte, Minijob"])}
            },
            "praktikum": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktikum"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtpraktikum, Freiwillige Praktika, Praxissemester"])}
            },
            "volunteer": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freiwilligenstelle"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BFD/FSJ, Famulatur, PJ, Ehrenamt"])}
            },
            "weiterbildung": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterbildung"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studiengänge und Kurse"])}
            },
            "werkstudent": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkstudent"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilzeit neben Studium"])}
            }
          }
        },
        "video_player": {
          "provider_video_abspielen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("provider")), "-Video abspielen"])}
        }
      }
    }
  }
}