export default {
  "en": {
    "js": {
      "components": {
        "apply": {
          "apply_form": {
            "bewerbung_unter_folgendem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the application using the following link"])},
            "bewerbung_vervollstandigen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complete application"])},
            "closed_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, applications for this position are no longer possible."])},
            "closed_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications closed"])},
            "die_firma_nutzt_ein_bewerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The company uses an applicant management system and asks you to complete your application using the following link:"])},
            "gender_options": {
              "diverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse"])},
              "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ms/Mdm"])},
              "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr"])}
            },
            "link_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the provider"])},
            "link_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please follow this link if this offer is of interest to you:"])},
            "living_in_germany_options": {
              "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I live in <b>another country</b>"])},
              "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I currently live <b>in Germany</b> / my official place of residence is in Germany"])}
            }
          },
          "apply_succeed": {
            "calendar": {
              "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download calendar reminder"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set a calendar reminder for 1 week later to follow up on the application if necessary."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar reminder"])}
            },
            "company_finder": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " other suitable companies with ", _interpolate(_named("jobCount")), " similar job offers."])}
            },
            "subscribe": {
              "button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Subscribe to ", _interpolate(_named("query"))])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive new job offers by email"])}
            },
            "subscription": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use our mobile app to keep up to date with new jobs."])}
            }
          },
          "empfehlungscode_list_item": {
            "entfernen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])}
          },
          "feedback_modal": {
            "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit feedback"])},
            "yes_no": {
              "no_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
              "yes_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
            },
            "zufriedenheit_options": {
              "no_statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No comment"])},
              "rather_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rather satisfied"])},
              "rather_unsatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rather unsatisfied"])},
              "satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satisfied"])},
              "unsatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dissatisfied"])}
            }
          },
          "prefilled_note": {
            "adresse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
            "alle_eingaben_von_diesem_g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all entries from this device"])},
            "angaben_anpassen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjust details"])},
            "anhange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
            "anschreiben_kommentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover letter/comment"])},
            "beim_absenden_einer_bewerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When submitting an application, all application documents were automatically saved in your browser and remain there. Your application details will only be sent to the respective company when you click on \"Apply now\" and will not be saved in our database."])},
            "datenschutzerklarung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data protection"])},
            "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
            "hilfe_zum_datenschutz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Help"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
            "schlie_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
            "schnellbewerbung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Express application"])},
            "sie_konnen_hier_mit_einem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can send an application with your previous information here with one click."])},
            "telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])}
          }
        },
        "apply_card": {
          "answer_those_questions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please answer the following questions from ", _interpolate(_named("company")), ":"])},
          "apply_now_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply now"])},
          "bms_privacy_hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The organization ", _interpolate(_named("organisation")), " uses an applicant management system to process your application. The data protection declarations for the storage and processing of your data can be found <a href=\"", _interpolate(_named("privacyPolicyUrl")), "\" target=\"_blank\" style=\"text-decoration: underline\">here</a>."])},
          "error_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following information is still incomplete:"])},
          "external_hint_hybrid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["IMPORTANT NOTE: The ", _interpolate(_named("company")), " uses an applicant management system. Your complete application will be sent to the company. However, if you do not receive a letter of confirmation of receipt within 24 hours, use the link to the application form directly in the system of the customer, which you will receive after submitting and we will send you again via e-mail."])},
          "external_hint_legacy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["IMPORTANT NOTE: The ", _interpolate(_named("company")), " uses an applicant management system. By clicking on the \"Apply\" button you will receive the link and will have to register again there."])},
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We would be happy to receive a short feedback on your satisfaction with empfehlungsbund.de"])},
          "feedback_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give feedback"])},
          "feedback_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your feedback! This helps us a lot in the further development of this website."])},
          "go_to_kanaleo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["go to kanaleo applicant survey"])},
          "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apply now to the ", _interpolate(_named("company")), " as a ", _interpolate(_named("job")), "."])},
          "kanaleo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the meantime, the employer asks you to provide anonymous feedback on kanaleo on how you became aware of the position."])},
          "kanaleo_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your employer feedback!"])},
          "next_steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Steps:"])},
          "postal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailing address"])},
          "previous_applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You already applied for this position on ", _interpolate(_named("date")), "."])},
          "privacy_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can find the <a href=\"https://www.fehlungsbund.de/datenschutz#werbungsformular\" target='_blank'>Privacy Policy of Empfehlungsbund and its job communities here</a>. Your data will only be temporarily stored here and will be deleted after a successful transmission to the organization."])},
          "required_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents required by the company:"])},
          "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check whether you meet these basic requirements:"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply now"])},
          "succeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application was sent successfully!"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])}
        },
        "berufe_selector": {
          "berufe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Jobs"])},
          "berufsprofile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["job profiles"])},
          "bewerbungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["applications"])},
          "empfehlende_arbeitgeber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recommending employers"])},
          "empfehlungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recommendations"])},
          "impressions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["impressions"])},
          "in_den_letzten_365_tagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the last 365 days:"])},
          "jobsuchende": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jobseekers"])},
          "tage_bis_zur_ersten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days until first application"])},
          "wahlen_sie_einen_berufstyp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a job type"])},
          "weitere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
        },
        "bookmark_button": {
          "aus_merkliste_entfernen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from watch list"])},
          "beworben": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Applied on ", _interpolate(_named("date"))])},
          "in_merkliste_aufnehmen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to watch list"])},
          "nicht_mehr_verfuegbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This position is no longer available."])}
        },
        "cart": {
          "active_codes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active recommendation codes"])},
          "cart_overview": {
            "alle_eingaben_von_diesem_g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all data from this device"])}
          },
          "codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Codes"])},
          "codes_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not yet deposited any referral codes."])},
          "delete_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all"])},
          "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Jobs"])},
          "jobs_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not yet saved any jobs."])},
          "jobs_empty_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find jobs"])},
          "to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to watch list"])}
        },
        "cart_overview": {
          "explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When submitting an application, all application documents were automatically saved in your browser and remain there. Your application details will only be sent to the respective company when you click on \"Apply now\" and is not stored in our database."])},
          "info_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So far you have not provided any application documents information."])},
          "personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your personal information"])},
          "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your letter of application"])}
        },
        "codes_modal": {
          "allow_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I allow companies to which I apply to, to contact the company that gave me the recommenation code, to ask them about my qualification."])},
          "save_code_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save referral code"])},
          "success_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have successfully added a ", _interpolate(_named("firma")), " referral code"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you receive a referral code?"])}
        },
        "contact_card": {
          "kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
          "termin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting"])}
        },
        "eb_livestream": {
          "events": {
            "apply_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An application has been sent"])},
            "passus_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One applicant was recommended to the network"])},
            "passus_click_recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A referral code has been assigned"])}
          }
        },
        "empfehlungscodes_field": {
          "active_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Referral Codes:"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add referral code"])},
          "did_you_receive_1_before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you a obtained a"])},
          "did_you_receive_2_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referral code (\"Empfehlungscode\")"])},
          "did_you_receive_3_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["?"])},
          "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only first-class professionals receive a referral code. The entrepreneurs of the community portals involved communicate regularly\nwho deserves a recommendation. Trust the judgment of the experts. Convince yourself of the strict rules in the\n<a class=\"text-blue-500 hover:text-blue-700\" href=\"https://www.empfehlungsbund.de/faq#Bewerber\" target=\"_blank\"> FAQs of the Empfehlungsbund </a>.\nUse the referral code for your professional success.\n"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add referral code"])},
          "recommended_by": {
            "referenzcode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recommended candidate, by ", _interpolate(_named("company"))])},
            "referenzcode2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Interesting candidate, from ", _interpolate(_named("company"))])}
          },
          "use_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use as a reference: The company I'm applying to here is welcome to ask the organization that issued me the referral code about the reasons for the referral during my application."])}
        },
        "empfehlungsgraph": {
          "empfehlungsaktivitat_wird": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graph is loading..."])}
        },
        "event_countdown": {
          "jetzt_anmelden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register now"])},
          "mehr_erfahren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
          "minuten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
          "noch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["still"])},
          "sekunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
          "stunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])},
          "tage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])}
        },
        "filter_wizard": {
          "checkboxes": {
            "mehr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
            "weniger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])}
          }
        },
        "history_event_page": {
          "alle_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All services"])}
        },
        "lazyload": {
          "wird_geladen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])}
        },
        "non_german_ip_interstitial": {
          "country_options": {
            "eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EU/EEA/Schengen"])},
            "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
            "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other country"])}
          },
          "modal_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It looks like you're not in Germany at the moment. Can you tell us where you are at the moment?"])},
          "notice": {
            "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to Jobs4International.de"])},
            "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, your details regarding work permit and residence do not match the requirements of this position. We recommend that you search Jobs4Internationals.de for suitable positions where these criteria are not mandatory. Companies are listed there that offer support in applying for a work permit for Germany and help with relocation.\n<br>\nOf course, you are free to apply for this position here too. However, there is a good chance that you will not receive any support and may be rejected.\n"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important information about your application"])}
          },
          "work_permit_options": {
            "germany_eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, I already have a work permit for Germany or another EU country."])},
            "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, I do not yet have a work permit for Germany or another EU country."])}
          }
        },
        "partner_review": {
          "averageScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total score"])},
          "beratungScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation and support"])},
          "bewertung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
          "bewertung_von": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review by"])},
          "commentRecommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendation"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
          "datum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
          "firmenname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company name"])},
          "partnerstimmen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partner voices"])},
          "partnerstimmen_wir_empfehl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner voices: We recommend specialists"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback from the last customer survey: What we like about Empfehlungsbund:"])},
          "sort_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
          "totalSatisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satisfaction"])},
          "year_membership": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["New member"]), _normalize([_interpolate(_named("count")), " Year of membership"]), _normalize([_interpolate(_named("count")), " Years of membership"])])}
        },
        "prices": {
          "form": {
            "benotigen_sie_einen_strong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need an <strong>automatic connection</strong> of your BMS to Empfehlungsbund?"])},
            "bms_option": {
              "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I <strong>use</strong> my own <strong>applicant management system</strong> (BMS)."])},
              "nein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I still <strong>do not have an applicant management system</strong>, or process the applications myself."])}
            },
            "ich_wunsche_dass_die_stron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I wish the <strong>job ads</strong> to be transferred or crawled from my career page/from my BMS."])},
            "ich_wunsche_die_automatisi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I would like the automated <strong>transfer of applicants</strong> from the EB Talent Cloud to my BMS. (Subject to feasibility)"])},
            "konnen_sie_prinzipiell_ihr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In principle, can you adapt your rejection letters to include a reference to the Empfehlungsbund community?"])},
            "mitarbeiter_kopfe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees (heads)"])},
            "rejection_option": {
              "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, I can in principle <strong>adjust</strong> my rejection letters, and include the reference."])},
              "nein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, I can't adjust my <strong>rejection letters</strong> in principle."])}
            },
            "verwenden_sie_ein_eigenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you use your own applicant management system?"])}
          },
          "price_card": {
            "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
            "star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices refer to the price lists as of 01.07.2022. Annual price dependent on the number of employees nationwide."])},
            "starting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starting*"])}
          }
        },
        "prices_app": {
          "anbindung_am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection at"])},
          "angebot_erzeugen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate offer"])},
          "anzahl_der_in_deutschland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of employees working in Germany/full-time positions"])},
          "anzahl_der_mitarbeiter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of employees"])},
          "anzeige": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vacancy"])},
          "anzeigenkampagne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad campaign"])},
          "anzeigenkampagne_fur_30_ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad campaign for 30 days on Empfehlungsbund.de and all matching affiliated job boards + Empfehlungsbund wide area network"])},
          "automatische_laufzeitverla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic runtime extension of up to 60 days until an application has been generated through us"])},
          "automatischer_import_amp_n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic import &amp; seamless integration of your applicant management system to the Empfehlungsbund network"])},
          "automatischer_import_in_di": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic import into the Bundesagentur für Arbeit (German Federal Employment Agency)"])},
          "des_empfehlungsbundes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of the Empfehlungsbund"])},
          "die_empfehlungsbundmitglie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Empfehlungsbund membership is an annual flat rate for all job advertisements in your company and is based on the number of permanent employees in Germany."])},
          "die_mitgliedschaft_enthalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership includes all benefits of the Ad Hoc campaign valid for 365 days"])},
          "ebms_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungsbund applicant management system EBMS for optimizing the candidate journey is available at no additional cost in the basic version for Empfehlungsbund members"])},
          "fachkrafteempfehlungsnetzw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Recommendation Network of the Empfehlungsbund"])},
          "fachkrafteempfehlungsnetzw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialist referral network"])},
          "fachkrafteempfehlungsnetzw2_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive additional Recommended Candidates through the cooperation with the Empfehlungsbund network. The members of the Empfehlungsbund recommend each other good candidates who were in the interview, but were not hired commission-free. Since 2009, more than 17k specialists for computer science, commercial job profiles and information technology have been recommended."])},
          "fachkrafteempfehlungsnetzw_tootip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a regular member, you have the opportunity to recommend candidates and receive recommended candidates. High visibility within the dynamic talent pool of actively seeking recommended candidates. Premium visibility within the entire Empfehlungsbund network incl. 14 other job platforms."])},
          "festangestellten_mitarbeit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permanent employees (full-time equivalents)"])},
          "firma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company"])},
          "individuelle_beratung_zur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual consulting for job optimization included"])},
          "jahresmitgliedschaft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual membership"])},
          "kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
          "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
          "netto_zahlbar_als_jahresmi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["net, payable as annual membership"])},
          "optionale_ma_nahmen_und_be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional measures and consulting to increase applicant satisfaction and employer brand and job optimization"])},
          "optionale_nutzung_aller_se": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional use of all services of the Empfehlungsbund without additional costs:"])},
          "optionale_schaltung_in_mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional placement in branded job boards with changing conditions"])},
          "preis_berechnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calculate price"])},
          "rechnungsadresse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address"])},
          "registrieren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
          "schaltung_arbeitsagentur_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment agency circuit, applicant response controlling, salary benchmarks, employee-recruit-employee program, and much more."])},
          "termin_vereinbaren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make an appointment"])},
          "volle_teilnahme_am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full participation in the"])},
          "zugang_uber_das_erweiterte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access via the extended cooperation network of the Recommendation Network to universities, associations, user groups, specialist initiatives"])}
        },
        "profile": {
          "conversation": {
            "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment"])},
            "choose_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a conversation to view the messages."])},
            "confirm_modal": {
              "text_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to end this conversation? Your profile will be anonymized again for this company and they will no longer be able to contact you. Please note that this action cannot be undone."])},
              "text_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to hide your information from this company? After you do this, this company can only see your anonymous data."])},
              "text_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to share your data with this company? This company can then see all the data you provided (address, phone number, CV, etc.)."])},
              "text_reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to reopen this conversation? Please note that the conversation will only be reopened if both sides agree to the request."])},
              "title_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End conversation"])},
              "title_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide data"])},
              "title_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclose data"])},
              "title_reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen conversation"])}
            },
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
            "end_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End conversation"])},
            "es_kann_eine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["However, it may take a while for a company to discover and contact you. In the meantime, you can browse our job board and quickly apply for the most personalized position based on your preferences."])},
            "fett": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fat"])},
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active conversations with companies"])},
            "hint_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: Remember, you remain anonymous to the companies contacted. However, if you decide to accept a job offer, you can fully transfer your personal information (name, phone number, resume file, etc.) to a company by clicking the 'Disclose Data' button in their chat window below . By the way, you can end the conversation with a contacted company at any time. In this case, we will NOT notify the company and your information will remain hidden from them."])},
            "hire_confirmations": {
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
              "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])},
              "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This company has marked you as employed by them. Please confirm this by clicking the 'Confirm' button below. If this is an error or you would like to decline the offer, click 'Decline'"])}
            },
            "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a company is interested in you, they open a new conversation and send you notifications about their job openings. If this happens, you will also be informed via email"])},
            "kursiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italic"])},
            "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading messages..."])},
            "message_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, your message couldn't be sent."])},
            "no_conversation_chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No conversation selected"])},
            "no_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the moment no company has contacted you."])},
            "placeholders": {
              "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This conversation has ended."])},
              "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a message..."])}
            },
            "private_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide data"])},
            "public_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclose data"])},
            "reopen_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen conversation"])},
            "response_time": {
              "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply within one day"])},
              "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replies within a few days"])},
              "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immediate response"])},
              "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick response"])}
            },
            "seen_by_org": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seen by ", _interpolate(_named("company"))])},
            "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
            "system_messages": {
              "backend": {
                "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Candidate no longer available. This conversation has been archived."])},
                "auto_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: This conversation has been automatically closed. (30 days of inactivity)"])},
                "closed_by_org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: You have ended the conversation."])},
                "closed_by_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Conversation ended."])},
                "confirm_hire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Talent has accepted the job offer."])},
                "decline_hire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Talent rejected the job offer."])},
                "hire_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: You have marked the talent as hired."])},
                "private_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Talent data has been hidden."])},
                "public_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: Talent data has been released."])},
                "reopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: This conversation has been reopened."])},
                "request_to_reopen_by_org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: You have requested to reopen the conversation."])},
                "request_to_reopen_by_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System: The candidate has requested to reopen the conversation."])}
              },
              "frontend": {
                "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This conversation has been archived."])},
                "auto_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This conversation has been automatically closed. (30 days of inactivity)"])},
                "closed_by_org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This company ended the conversation."])},
                "closed_by_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have ended this conversation."])},
                "confirm_hire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You now work for this company."])},
                "decline_hire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You rejected the job offer."])},
                "hire_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This company hired you. To confirm or reject this, click on the \"Details\" button above.\n"])},
                "private_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You hid your information from this company."])},
                "public_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have shared your data with this company."])},
                "reopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This conversation has been reopened."])},
                "request_to_reopen_by_org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This company has requested to reopen the conversation."])},
                "request_to_reopen_by_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have requested to reopen the conversation."])}
              }
            },
            "upload_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, something went wrong. File must not be larger than 10MB."])}
          },
          "hinweis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: This information will only be made available to companies once you express interest in a specific job offer. Until then, companies can only search for details in the 'Anonymous Profile' tab. You should only complete this page when you find an offer that interests you."])},
          "info_banner": {
            "click_underneath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the button below to verify your phone number."])},
            "dein_profil_ist_bereits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile is already visible to companies. Your dream job is just around the corner. Good luck!"])},
            "hey_willkommen_zurueck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hey, welcome back!"])},
            "phone_verification_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It looks like you haven't verified your phone number yet. Add your phone number to receive SMS notifications when companies contact you to not miss out on any job offers."])}
          },
          "job_offer": {
            "about_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the Company"])},
            "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesting"])},
            "accepted_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted Job Offers"])},
            "answer_all_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in all mandatory fields and the questions you were asked."])},
            "answer_those_questions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please answer the following questions/fill in all mandatory fields required by ", _interpolate(_named("company"))])},
            "application_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Your Profile"])},
            "can_close_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can close this page now."])},
            "check_the_following_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Please verify your submission details for the offer from ", _interpolate(_named("company")), ":"]), _normalize(["Please verify your submission details for the job from ", _interpolate(_named("company")), ":"])])},
            "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created on"])},
            "data_update_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile data will be automatically updated when you make changes here."])},
            "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not interesting"])},
            "hired_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hired Job Offers"])},
            "job_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Offer Details"])},
            "message_from_company": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Offer from ", _interpolate(_named("company")), ":"])},
            "no_file_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one file to send."])},
            "no_job_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently, there are no job offers available."])},
            "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This job offer is no longer available."])},
            "past_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past Job Offers"])},
            "pending_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Job Offers"])},
            "postal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Postal Address"])},
            "reject_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline Job Offer"])},
            "reject_survey": {
              "es_ist_wichtig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's important that you make the best decision for yourself and we fully respect your choice. If you need our support again in the future, please do not hesitate to contact us"])},
              "no_more_offer_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I do not want to receive more offers from this company."])},
              "questions": {
                "high_requirements": {
                  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requirements of the job offer were too high for me:"])}
                },
                "low_salary": {
                  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The salary of the job offer was too low for me:"])}
                },
                "options": {
                  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disagree"])},
                  "not_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not sure"])},
                  "rather_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rather disagree"])},
                  "rather_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rather agree"])},
                  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree"])}
                },
                "other": {
                  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have other reasons:"])}
                },
                "unfitting_description": {
                  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The job description of the job offer was too vague/boring for me:"])}
                },
                "unfitting_location": {
                  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The location of the job offer was not suitable for me:"])}
                }
              },
              "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline this job offer"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a few minutes? Answering these questions helps us find better job offers for you."])},
              "vielen_dank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your reply. Your decision has been successfully registered."])},
              "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why did you decline this job offer?"])}
            },
            "send_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit My Profile"])},
            "show_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Details"])},
            "state": {
              "text": {
                "accepted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have already submitted your profile for this job offer on ", _interpolate(_named("date")), "."])},
                "documents_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have already submitted your profile for this job offer on ", _interpolate(_named("date")), "."])},
                "hired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been hired for this position."])},
                "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I find this job offer"])},
                "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This job offer is no longer available."])},
                "rescinded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This job offer is no longer available."])}
              }
            },
            "stelle_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Job Description"])}
          },
          "job_suggestions": {
            "all_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seen all job suggestions for this week"])},
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no new positions available for this week. Check back next week!"])},
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your weekly job suggestions"])},
            "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the meantime, while you are waiting for offers from companies, we have prepared a few suitable suggestions for you here. You can also easily apply for these job offers yourself using your EB Talents profile.\n"])},
            "load_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load new job suggestions"])},
            "page_von": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Page ", _interpolate(_named("page")), " of ", _interpolate(_named("total"))])}
          },
          "labels": {
            "active_recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Recommendations"])},
            "blockierte_unternehmen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked Companies"])},
            "profile_link_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you to link your social media profiles?"])},
            "select_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least 1 file."])},
            "select_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected files will be sent to the company."])},
            "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload your resume."])}
          },
          "login": {
            "email_gesendet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent"])},
            "gib_deine_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address to log in."])},
            "link_senden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Link"])},
            "wir_haben_dir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you an email with a one-time login link. Please check your inbox and click on the link to log in."])},
            "wir_konnten_deine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not find your email address in our system. Please check your input and try again."])}
          },
          "phone_verification": {
            "bitte_gib_eine_gueltige": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid phone number."])},
            "code_falsch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The code is incorrect. Please try again."])},
            "deine_telefonnummer_ist_noch_nicht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number has not yet been verified."])},
            "es_kann_bis_zu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It may take up to 5 minutes for the SMS to arrive."])},
            "gib_einfach_deine_telefonnummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your phone number including country code below and click \"Send SMS for Verification\". You will then receive a code via SMS that you can enter here."])},
            "hint_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hint: Unless you explicitly allow the transfer of your data to a specific company, employers will not be able to see your phone number. Additionally, you will receive an SMS notification each time a company contact you. However, this can be manually disabled in the profile setting.\n"])},
            "keine_sms_erhalten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No SMS received? Click \"Resend SMS\" to try again."])},
            "leider_koennen_wir_deine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, we cannot verify your phone number at the moment. Please try again later."])},
            "nochmal_senden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend SMS"])},
            "sms_senden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send SMS for Verification"])},
            "telefonnummer_bereits_verifiziert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This phone number has already been verified."])},
            "telefonnummer_verifiziert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number has been verified."])},
            "telefonnummer_verifiziert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax and wait for the companies to contact you."])},
            "ueberpruefe_dein_hand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your phone for the verification code"])},
            "verifizierte_profile_wirken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified profiles receive more attention and faster contact via SMS when new inquiries from employers come in."])},
            "zu_viele_anfragen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many requests. Please try again in a minute."])}
          },
          "profile_preview": {
            "dein_anonymes_kurzprofil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your anonymous short profile will be transferred"])},
            "deine_daten_wurden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data has been successfully updated."])},
            "nur_folgende_daten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONLY the following data will be public and visible to relevant companies:"])}
          },
          "profile_settings": {
            "bist_du_sicher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete your profile?"])},
            "delete_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Profile"])},
            "delete_profile_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you delete your profile, all your data will be permanently deleted and you will not receive any more job offers. If you want to log in again, you will have to create a new profile."])},
            "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, delete profile"])},
            "nein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, keep profile"])},
            "sms_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS Notifications"])},
            "sms_notification_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate SMS notifications to receive important updates whenever a company contacts you (Only available for verified phone numbers)."])},
            "sms_notification_ist_aktiviert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS notifications are currently activated"])},
            "sms_notification_ist_deaktiviert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS notifications are currently deactivated"])},
            "sms_notification_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate SMS notifications"])},
            "sms_notification_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate SMS notifications"])}
          },
          "quick_info": {
            "abmelden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
            "bist_du_sicher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to log out?"])},
            "bist_du_sicher_dein_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure? Your profile will no longer be visible to companies."])},
            "privat_schalten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Private"])},
            "profil_veroeffentlichen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Profile"])},
            "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
            "telephone_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add phone number"])},
            "verify_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify now"])}
          },
          "recommendations": {
            "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this?"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a referral code? Enter it here!"])}
          },
          "tables": {
            "abschluss_und_zertifikate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualifications and certificates"])},
            "allgemeine_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Information"])},
            "anstellungsform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Employment"])},
            "bearbeiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit job titles"])},
            "berufliche_sprachkenntnisse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional language skills"])},
            "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizenship"])},
            "deine_allgemeinen_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your general information"])},
            "deine_ausbildung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your education"])},
            "deine_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your referral code"])},
            "deine_daten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Personal Data"])},
            "deine_faehigkeiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Skills"])},
            "deine_wunschstelle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desired Job"])},
            "deine_zertifikate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your certificates"])},
            "du_hast_noch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't added any certificates yet."])},
            "empfehlungscode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral code"])},
            "erfahrungsstufe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience level"])},
            "firmenblockliste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked companies"])},
            "firmenname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
            "gewuenschter_arbeitsort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desired Workplace"])},
            "hoechster_abschluss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest qualification"])},
            "jobs_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desired Job Titles"])},
            "kein_unternehmen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No blocked companies"])},
            "keine_empfehlungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active recommendations"])},
            "kompetenz_stufe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competency level"])},
            "kompetenzen": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Competence"]), _normalize(["Competencies"])])},
            "kompetenzen_bearbeiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit competencies"])},
            "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
            "name_des_zertifikats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate name"])},
            "neues_zertifikat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New certificate"])},
            "niveau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level / Grade (optional)"])},
            "niveau_stufe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level / Grade"])},
            "phone_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone verification"])},
            "profile_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile preview and unlock"])},
            "profile_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Settings"])},
            "sprache": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
            "sprachkenntnisse_bearbeiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit language skills"])},
            "stellenbezeichnung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job title"])},
            "zertifikat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
            "zertifikat_bearbeiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit certificate"])},
            "zertifikat_hinzufuegen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add certificate"])}
          },
          "tabs": {
            "anonymous_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymous Profile"])},
            "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
            "job_board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Board"])},
            "job_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Offers"])},
            "job_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Subscriptions"])},
            "personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Data"])}
          }
        },
        "progress": {
          "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
          "competence_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level of Competence"])},
          "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
          "country_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Country"])},
          "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest Qualification"])},
          "degree_specialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree Specialization"])},
          "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents/Résumé"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the files you want to send"])},
          "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
          "germany_residency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residency in Germany"])},
          "hint": {
            "application_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name, addresse und telephone"])},
            "company_blocklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can block offers from unwanted companies"])},
            "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree, certificates, ..."])},
            "job_desire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desired job titles, desired workplace, ..."])},
            "recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can enter a referral code"])},
            "skillset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work permit, competencies, language abilities, ..."])}
          },
          "job_titles_preference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred Job Titles"])},
          "language_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language Abilities"])},
          "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
          "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Locations"])},
          "missing_steps": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Next step: enter your <b> ", _interpolate(_named("fields")), "</b>"])},
          "portal_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Career Portal"])},
          "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
          "ready_to_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willingness to Relocate"])},
          "salary_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary Interval"])},
          "salary_preference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred Salary"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
          "sub_job_types_preference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form of Employment"])},
          "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
          "work_permit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Permit"])}
        },
        "scroll_to_top": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll all the way to the top."])}
        },
        "talent_profile": {
          "answers": {
            "eu_citizen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am an EU citizen"])},
            "limited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited work permit"])},
            "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
            "no_work_permit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No work permit"])},
            "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited work permit"])},
            "work_permit_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None of the above options"])},
            "work_permit_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have a valid work permit in Germany"])},
            "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
          },
          "competences": {
            "chosen_competences": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Please select at least one competence or use the input for your own professional qualifications or skills."]), _normalize(["You have selected 1 competence."]), _normalize(["You have selected ", _interpolate(_named("count")), " competences."])])},
            "competence_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a competence or add one"])},
            "custom_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Entered Competences"])},
            "frequently_chosen_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Chosen Competences"])},
            "hide_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Competences"])},
            "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: To get the best job title suggestions, we recommend selecting at least one of the provided competences. This information helps us present you with tailored and relevant results. Your selection of competences significantly contributes to our ability to provide you with the best career opportunities. Thank you for your cooperation!"])},
            "is_important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Important"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Select your field of competence"])},
            "label2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Add at least one competence"])},
            "more_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show More Competences"])},
            "no_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No competences were found for your entered search term. Click 'Add' to enter your own competences."])},
            "no_portaltype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your field of competence first."])},
            "only_5_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can select up to 5 competences."])},
            "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Competencies"])},
            "significance_levels": {
              "important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important"])},
              "not_important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less Important"])}
            },
            "weiter_entwickeln": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open to Offers"])}
          },
          "degrees": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is your highest/latest professional qualification or school degree?"])},
            "options": {
              "apprenticeship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vocational Training"])},
              "bachelor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor"])},
              "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master/Diploma/State Examination"])},
              "meister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Craftsman"])},
              "no_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Degree"])},
              "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
              "phd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PhD/Doctorate"])},
              "technician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technician"])}
            }
          },
          "degree_specialization": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field of study/name of professional qualification"])}
          },
          "errors": {
            "combined_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify at least one competence, its level, and an area in which you want to develop."])},
            "competence_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify all competence levels."])},
            "competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least one competence and at most 5 competences."])},
            "data_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept the data privacy statement."])},
            "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a degree."])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid email address."])},
            "important_competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify at least one area in which you want to develop."])},
            "job_titles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify at least one job title."])},
            "language_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify at least one language."])},
            "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops. There was an issue creating your profile. Please try again later."])},
            "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept the data privacy statement."])},
            "sub_job_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least one job type."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops. There was an issue updating your profile. Please try again later."])},
            "upload_and_select_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload and select at least one file."])},
            "work_locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least one work location option."])},
            "work_permit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must answer all questions."])}
          },
          "experience_levels": {
            "explanations": {
              "advanced_beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - 2 years of experience"])},
              "competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 - 5 years of experience"])},
              "expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 10 years of experience"])},
              "novice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No experience"])},
              "proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 - 10 years of experience"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate your level of competence:"])},
            "options": {
              "advanced_beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junior"])},
              "competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermediate"])},
              "expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert"])},
              "novice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novice"])},
              "proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior"])}
            }
          },
          "generic": {
            "abbrechen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
            "bearbeiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
            "beenden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
            "in_bearbeitung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
            "schliessen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
            "speichern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
            "status": {
              "application_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops. Your application couldn't be submitted. Please try again later."])},
              "application_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your application has been successfully submitted."])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops. There was a problem updating your data. Please try again later."])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data has been successfully updated."])},
              "synchronised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data has been automatically synchronized with your profile."])}
            },
            "validations": {
              "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])}
            }
          },
          "important_competences": {
            "explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose all competences for which you want to receive offers or in which you want to develop."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For these topics, I would like to receive offers?"])},
            "not_chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 competence"])}
          },
          "job_subscriptions": {
            "active_jobs_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Job Subscriptions"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribe"])},
            "do_you_know_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you already know our app for iOS and Android? Stay up-to-date on the go and browse all job listings from Empfehlungsbund. <div><a href='https://www.empfehlungsbund.de/app'>More information and download options can be found here.</a></div>"])},
            "job_search_and_abo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Search and Subscriptions"])},
            "no_active_jobs_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, you have no active job subscriptions yet."])},
            "placeholder_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where?"])},
            "placeholder_q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What?"])},
            "search_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
            "search_position_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location, e.g., 'Dresden'"])},
            "search_q_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job title or keyword, e.g., 'Developer' or 'Java'"])},
            "stellen_durchsuchen_und_we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Jobs and Add More Job Subscriptions"])},
            "subscription_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops. There was a problem creating your job subscription. Please try again later."])},
            "subscription_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your job subscription has been successfully created."])},
            "suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some suggestions for you:"])}
          },
          "job_titles": {
            "examples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g., Software Developer, Sales Manager, Marketing Director"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us your desired job title"])},
            "label_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Desired Job Titles"])},
            "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, there are no results for this search. Click Add or press Enter to use your input."])},
            "often_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Used Job Titles"])},
            "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for job titles..."])},
            "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your provided job titles:"])},
            "suggestion_hint": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hint: Use the search field to get intelligent suggestions."]), _normalize(["Here's a suggestion for a job title."]), _normalize(["Here are a few suggestions for a job title."])])}
          },
          "languages": {
            "fluency_levels": {
              "a1_a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Proficiency (A1/A2)"])},
              "b1_b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good to Fluent (B1/B2)"])},
              "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Proficiency (C1, DSH, TestDaF, IELTS6+, TOEFL 100+)"])},
              "c2_native": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Native Level (C2)"])},
              "text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["How well do you master this language?"]), _normalize(["How well do you master these languages?"])])}
            },
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
            "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a language, e.g., English, Spanish, ..."])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which languages do you speak?"])}
          },
          "motivation": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is especially important to you in an employer/position?"])},
            "label_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Preferences for an Employer"])}
          },
          "pages": {
            "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
            "add_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub, Stack Overflow, LinkedIn, Xing, personal website, ..."])},
            "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
            "fields": {
              "company_blocklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked Employers"])},
              "competence_ids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Competencies"])},
              "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
              "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Desired Work Location"])},
              "recommendation_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Code"])}
            },
            "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
            "prefill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our system has detected that you previously entered a referral code and/or other information based on your previous session. Would you like to prefill these fields?"])},
            "prefill_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill"])}
          },
          "phone_number": {
            "placeholders": {
              "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. +49"])},
              "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. 123456789"])}
            }
          },
          "placeholders": {
            "company_blocklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name, e.g., Siemens, Bosch, ..."])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Email Address"])},
            "motivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g., Work atmosphere, training opportunities, ..."])},
            "degree_specialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g., Computer Science, Business Administration, ..."])},
            "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount in Euro, e.g., €50,000"])}
          },
          "profile": {
            "personal_data": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])}
            }
          },
          "questions": {
            "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you an EU citizen or do you have a valid work permit in Germany?"])},
            "current_employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you currently employed?"])},
            "employer_hints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why do we need this information? Based on our experience, job offers from previous employers can sometimes be unattractive. Therefore, we ask you to provide your current/previous employers here in order to find the best possible job for you. If you wish to continue receiving potential job offers from previous companies, please leave the field above empty. Your privacy is important to us, and we do not share your information with other individuals or groups."])},
            "employer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your privacy is important to us! List all employers you do not want to receive job offers from."])},
            "german_residency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you currently reside in Germany?"])},
            "german_residency_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reside in Germany"])},
            "last_employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you been employed in the last 12 months?"])},
            "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Nationality"])},
            "ready_to_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you willing to work in Germany?"])},
            "ready_to_move_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willing to work in Germany"])},
            "work_permit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Work Permit"])}
          },
          "registration_hints": {
            "data_privacy_accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Privacy Statement"])},
            "ich_habe_die": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree that I can be found anonymously by companies in the talent pool. I accept the electronic storage of my data in accordance with the <a href=\"/datenschutz\">privacy policy</a>."])},
            "nach_erfolgreich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After successful registration, you will receive an email with an activation code. Please note that this code expires within 3 days of receipt. <br><br>Once you have activated your account, you will have access to a personal profile that you can configure according to your preferences. Please note that you will need to make your profile searchable for employers after that. "])},
            "unser_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Talents Program provides you with a comprehensive platform for career development and job placement. Use this opportunity to optimize your profile and get noticed by companies. We are confident that we can help you achieve your career goals."])},
            "wir_freuen_uns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are delighted to welcome you to our network and are happy to assist with any questions you may have."])}
          },
          "response_modal": {
            "error_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, there was an issue creating your profile. Please try again later."])},
            "error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error! Please retry again later."])},
            "success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We've just sent you an email. Please check your email inbox, and possibly your spam/junk folder, and click on the activation link within the email. After activation, you can access your profile and benefit from our Empfehlungsbund Talent to find your dream employer/company. We wish you a great start and a successful career."])},
            "success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almost there!<br>Please check your inbox now."])}
          },
          "salary_intervals": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desired Salary (Gross, EUR)"])},
            "options": {
              "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly Wage"])},
              "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Salary"])},
              "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Salary"])}
            }
          },
          "stepper_steps": {
            "fifth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Email Address"])},
            "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Profile"])},
            "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Preferences"])},
            "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
            "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Job Titles"])},
            "show_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Error"])},
            "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About You"])}
          },
          "sub_job_types": {
            "group_1": {
              "fachkraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skilled Worker"])},
              "freelancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancer"])},
              "fuehrungskraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
              "hilfskraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistant"])},
              "lernende": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprentice"])}
            },
            "group_2": {
              "ausbildung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Education"])},
              "duales_studium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dual Studies"])},
              "praktikum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship or Mandatory Internship"])},
              "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volunteer Position"])},
              "werkstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Assistant"])}
            },
            "main_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Employment:"])},
            "options": {
              "ausbildung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprenticeship"])},
              "duales_studium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dual Studies"])},
              "fachkraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skilled Worker"])},
              "freelancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancer"])},
              "fuehrungskraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
              "hilfskraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistant"])},
              "praktikum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship"])},
              "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volunteer Position"])},
              "werkstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Assistant"])}
            },
            "sub_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What role are you looking for?"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What type of employment are you looking for?"])}
          },
          "success": {
            "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almost there! Please check your email for the activation link. After activation, you can access your profile and benefit from our Empfehlungsbund Talent to find your dream employer/company. We wish you a great start and a successful career."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile has been successfully updated."])}
          },
          "work_location": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Preferred Work Location:"])},
            "options": {
              "any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any location in Germany"])},
              "only_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fully Remote (100% Home Office)"])},
              "onsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onsite in the following locations"])},
              "onsite_with_radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onsite in a location with a radius"])},
              "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fully Remote (100% Home Office)"])},
              "willing_to_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am willing to relocate"])}
            },
            "options_explain": {
              "any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are willing to relocate and are looking for jobs across Germany."])},
              "onsite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are looking for jobs in: ", _interpolate(_named("ort")), "."])},
              "onsite_with_radius": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are looking for jobs within a radius of ", _interpolate(_named("radius")), " km around: ", _interpolate(_named("ort"))])},
              "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are looking for 100% remote jobs."])}
            },
            "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within"])},
            "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g., Dresden, Berlin, Munich, ..."])}
          }
        }
      },
      "elements": {
        "upload_field": {
          "is_uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is uploaded:"])},
          "or_drag_and_drop_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag and drop here"])},
          "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
          "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
          "upload_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a document"])}
        }
      },
      "form": {
        "messages": {
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid email address"])},
          "fileRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to upload at least one file"])},
          "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("property")), " must be specified"])}
        },
        "properties": {
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City/Municipality"])},
          "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment / Cover letter"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your country of residence from the list"])},
          "current_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which country do you currently live in?"])},
          "current_work_permit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you already have a work permit for Germany or the EU?"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
          "feedback": {
            "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other comment"])}
          },
          "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application documents"])},
          "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given Name"])},
          "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desired salutation"])},
          "github_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Github URL"])},
          "home_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify your home country"])},
          "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name / family name"])},
          "livingInGermany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where is your current place of residence?"])},
          "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workplace"])},
          "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
          "question_answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application short questions"])},
          "recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you recommend Empfehlungsbund?"])},
          "satisfaction_with_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How satisfied have you been with the application process so far?"])},
          "satisfaction_with_bms_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How satisfied were you with the applicant tracking system(s)?"])},
          "satisfaction_with_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How satisfied are you with the job offer on the portal?"])},
          "satisfaction_with_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How satisfied were you with the presentation of the companies?"])},
          "satisfaction_with_usability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How satisfied are you with the operation of the portal?"])},
          "search_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search term(s)"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street address"])},
          "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
          "telephone_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone prefix"])},
          "telephone_suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone suffix"])},
          "website_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a website?"])},
          "would_use_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you apply again, would you choose this job-board again?"])},
          "xing_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xing URL"])}
        }
      },
      "wizard": {
        "app": {
          "all_stellenangebot_der": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All job offers from Empfehlungsbund-Partner in one App."])},
          "bewerben": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
          "erhalten_sie_neue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive new job offers via E-Mail or directly on your smartphone."])},
          "kein_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No login, no registration required."])},
          "sie_haben_eine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Found a job that suits you? Then apply directly via the app."])},
          "sie_koennen_mit_der": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can start searching for jobs immediately, no information required"])},
          "such_abo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search subscription"])}
        },
        "components": {
          "fairekarriere_widget": {
            "bewertungen_von_mitarbeite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluations of employees and applicants:"])}
          },
          "organisation_details": {
            "auszeichnungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employer awards"])},
            "behance_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behance profile"])},
            "facebook_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook Profile"])},
            "flickr_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flickr profile"])},
            "gesuchte_kompetenzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologies and Competences:"])},
            "github_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub profile"])},
            "instagram_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram profile"])},
            "karriere_seite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Career page"])},
            "linkedin_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn profile"])},
            "pinterest_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinterest profile"])},
            "podcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podcast"])},
            "standorte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
            "twitter_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter profile"])},
            "weiterfuhrende_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Links"])},
            "youtube_kanal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube channel"])}
          },
          "reward_card": {
            "logo_der_auszeichnung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award logo"])},
            "mehr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])}
          },
          "subscribe_card": {
            "dann_melden_sie_sich_hier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Then register here with your email. You will then be informed immediately when suitable employers or job offers to your search queries are found."])},
            "ihr_wunschstelle_ist_nicht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your desired position is not listed?"])},
            "legen_sie_sich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or create an anonymous profile in 5 minutes and let interesting employers find you."])},
            "profil_anlegen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create profile"])},
            "sentence": {
              "with_location_query": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you are only interested in jobs from <strong>", _interpolate(_named("location")), " for ", _interpolate(_named("query")), "</strong>, you will also find all suitable jobs on ", _interpolate(_named("name"))])},
              "with_query": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you are only interested in jobs <strong>for ", _interpolate(_named("query")), "</strong>, then you will also find all suitable jobs on ", _interpolate(_named("name"))])}
            }
          },
          "subscribe_form": {
            "ihre_mail_de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yout", "@", "mail.com"])},
            "wir_haben_ihnen_soeben_ein": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We've just sent you a confirmation email to ", _interpolate(_named("email")), ". Please click on the link in the mail to find out about new interesting ones"])}
          }
        },
        "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
        "filter_bar": {
          "100_remote_ausblenden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide 100% remote"])},
          "arbeitgeber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter employers"])},
          "arbeitsort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workplace"])},
          "laden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
          "less_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide filters"])},
          "mehr_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More filters"])},
          "nur_100_remote_stellen_anz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show fully remote (100% remote) jobs"])},
          "radius_zur_umkreissuche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radius for search"])},
          "standort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
          "suchbegriff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search term"])},
          "tatigkeitsfeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["field of activity"])},
          "unternehmensgro_e_mitarbei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company size (employees)"])}
        },
        "job_list": {
          "initiativ_bewerben": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send an unsolicited application"])},
          "mehr_jobs_length_show_anze": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Show more (", _interpolate(_named("jobs_length_show")), ")."])},
          "passende_stellenangebote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching vacancies:"])}
        },
        "job_list_item": {
          "jetzt_bewerben": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply now!"])},
          "stelle_einklappen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse job description"])}
        },
        "job_modal": {
          "arbeitsort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workplace:"])},
          "schlagworte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords:"])}
        },
        "location": {
          "100_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% remote"])},
          "anderer_arbeitsort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other place of work"])},
          "firmen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 company"]), _normalize([_interpolate(_named("count")), " companies"])])},
          "haufige_arbeitsorte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most common places of work:"])},
          "home_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work from home (WFH/Remote)"])},
          "jobs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 job"]), _normalize([_interpolate(_named("count")), " jobs"])])},
          "standort_durch_dein_gerat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determine location through your device"])},
          "stellen_in_city_suchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find jobs"])},
          "ungefahrer_standort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approximate location"])},
          "wird_bestimmt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...estimating position"])}
        },
        "organisation_card": {
          "anzahl_mitarbeiter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of employees"])},
          "bewertung": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Employer-Rating: ", _interpolate(_named("rating"))])},
          "bewertung_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees:"])},
          "mehr_zur_organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More about the organization"])},
          "very_good_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very good candidate experience!"])},
          "weniger_zur_organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less on organization"])}
        },
        "organisation_list": {
          "found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 company found."]), _normalize([_interpolate(_named("count")), " companies found."])])},
          "loaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])}
        },
        "remote_type": {
          "full_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% remote (WFH)"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not specified"])},
          "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid home office"])}
        },
        "salary_interval": {
          "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/hour"])},
          "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/month"])},
          "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/year"])}
        },
        "sub_job_type": {
          "types": {
            "all": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
            },
            "ausbildung": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company training"])}
            },
            "duales_studium": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooperative education"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combined vocational training und degree program, sandwich courses"])}
            },
            "fachkraft": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full-time, Part-time, with professional degree"])}
            },
            "freelancer": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractor"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancers"])}
            },
            "fuehrungskraft": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executive"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managers, leaders, directors"])}
            },
            "hilfskraft": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helper"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary workers, \"Mini job\", Unlearned"])}
            },
            "praktikum": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory or voluntary internships, practical semester"])}
            },
            "volunteer": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volunteer position"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German \"BFD/FSJ\", clerkship, honorary post"])}
            },
            "weiterbildung": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further education"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree programs and courses"])}
            },
            "werkstudent": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working student"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part-time during study"])}
            }
          }
        },
        "video_player": {
          "provider_video_abspielen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Play ", _interpolate(_named("provider")), " video"])}
        }
      }
    }
  }
}